import React from 'react';
import { connect } from 'react-redux';
import ErrorPage from '../../../components/ErrorPage'
import { Helmet } from 'react-helmet';
import { Card, CardBody, CardGroup, Alert, CardTitle } from 'reactstrap'

class DataView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showElem: window.sessionStorage.role == 'admin',
            alerts: [],
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        try {
            for (let i in nextProps) {
                if (nextProps[i] && nextProps[i].statusCode) {
                    if (nextProps[i].statusCode == 401) {
                        this.props.history.push("/logout")
                    } else if (nextProps[i].statusCode !== 200 && nextState == this.state) {
                        var alerts = this.state.alerts
                        alerts.push({
                            modal: true,
                            message: nextProps[i].message,
                            type: "danger"
                        })
                        this.setState({
                            alerts: alerts
                        })
                    }
                }
            }
        } catch (e) { }
        return true;
    }
    renderAlert(alert, id) {
        return (
            <Alert color={alert.type} isOpen={alert.modal} fade={false} key={id} toggle={e => this.onDismiss(e, id)}>
                {alert.message}
            </Alert>
        )
    }
    onDismiss(e, id) {
        let alerts = this.state.alerts
        alerts[id].modal = false

        this.setState({
            alerts: alerts
        })
    }



    componentDidMount() {

    }

    render() {
        return (
            <ErrorPage title="Welcome" message="功能开发中，敬请期待。" />
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {

    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataView);