import React from 'react';
import { connect } from 'react-redux';

import _ from 'loadsh'
import { getConfigAction, getLastIdAction } from './actions';
import { LANGUAGE } from '../../../config/Language';
import {
    Col,
    Label,
    Input,
    FormGroup, Alert,
    Card, CardGroup, CardTitle
} from 'reactstrap';
import AdminLayout from '../../../containers/Admin/AdminLayout'

class SendSMS extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showElem: window.sessionStorage.role == 'admin',
            alerts: [],
            errorShow: false,
            errorMessage: "",
            data: {
                phone: '',
                sign: 323921,
                template: 770227
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        try {
            try {
                for (let i in nextProps) {
                    if (nextProps[i] && nextProps[i].statusCode) {
                        if (nextProps[i].statusCode == 401) {
                            this.props.history.push("/logout")
                        } else if (nextProps[i].statusCode !== 200 && nextState == this.state) {
                            this.setState({
                                alert: {
                                    modal: true,
                                    message: nextProps[i].message,
                                    type: "danger"
                                }
                            })
                        }
                    }
                }
            } catch (e) { }
        } catch (e) { }

        return true;
    } 
    renderAlert(alert, id) {
        return (
            <Alert color={alert.type} isOpen={alert.modal} fade={false} key={id} toggle={e => this.onDismiss(e, id)}>
                {alert.message}
            </Alert>
        )
    }
    onDismiss(e, id) {
        let alerts = this.state.alerts
        alerts[id].modal = false

        this.setState({
            alerts: alerts
        })
    }



    componentDidMount() {
        this.props.getConfig()
    }

    handleFilter(ev) {
        let data = this.state.data
        data[ev.target.name] = ev.target.value
        if (ev.target.name == 'template') {
            for (let i in this.props.config.data.templates) {
                if (this.props.config.data.templates[i].id == ev.target.value)
                    data['paramCount'] = this.props.config.data.templates[i].paramCount
            }
        }

        this.setState({
            data: data
        })
    }


    rangeOptions(item) {
        return (
            <option value={item.id} key={item.id}>{item.name}</option>
        )
    }

    renderParams(count) {
        return (
            <Col>
                <Label for="examplePassword">参数{count}</Label>
                <Input type="text" name={`params${count}`} onChange={(ev) => this.handleFilter(ev)} />
            </Col>
        )
    }

    send(e) {
        console.log(this.state.data)
    }

    arrFind(arr, key, val) {
        for (let i in arr) {
            if (arr[i][key] == val) {
                return arr[i]
            }
        }
    }

    render() {
        try {
            var signs = [], templates = [], defaultTemplate = 0, params = this.state.data, content, phones, smsParams = []
            if (Object.keys(this.props.config.data).length) {
                signs = Object.keys(this.props.config.data.signs).map(key => this.rangeOptions(this.props.config.data.signs[key], key))
                templates = Object.keys(this.props.config.data.templates).map(key => this.rangeOptions(this.props.config.data.templates[key], key))

                let sign = this.arrFind(this.props.config.data.signs, 'id', params.sign).name
                let template = this.arrFind(this.props.config.data.templates, 'id', params.template).template

                params.paramCount = params.paramCount || 2

                if (sign && template) {
                    content = `【${sign}】${template}`
                }

                for (let i = 1; i <= params.paramCount; i++) {
                    if (params[`params${i}`]) {
                        content = content.replace(`{${i}}`, params[`params${i}`])
                    }
                    smsParams[i] = this.renderParams(i)
                }

                if (params.phone) {
                    phones = params.phone
                }
            }
        } catch (e) { }

        
        try {
            var alerts = Object.keys(this.state.alerts).map(key => this.renderAlert(this.state.alerts[key], key))
        } catch (e) { }

        return (
            <AdminLayout modal={this.state.modal} props={this.props} className="send-sms">
                <CardGroup>
                    <Card>
                        <CardTitle>
                            <i className="iconfont iconfasongduanxin"></i>参数设置
                            </CardTitle>
                        <FormGroup row className="search-box">
                            <Label for="examplePassword">签名</Label>
                            <Input bsSize="sm" type="select" name="sign" onChange={(ev) => this.handleFilter(ev)}>
                                {signs}
                            </Input>

                            <Label for="examplePassword">模板</Label>
                            <Input bsSize="sm" type="select" name="template" onChange={(ev) => this.handleFilter(ev)} defaultValue={defaultTemplate}>
                                {templates}
                            </Input>
                        </FormGroup>
                        <Col>
                            <Label for="examplePassword">接收号码，多个用半角分号隔开</Label>
                            <Input type="textarea" name="phone" onChange={(ev) => this.handleFilter(ev)} rows="5" />
                        </Col>

                        {smsParams}

                    </Card>
                    <Card>
                        <CardTitle>
                            <i className="iconfont iconyulan"></i>
                            预览
                            </CardTitle>
                        <FormGroup >
                            <div className="iphonePreview"></div>
                            <Col style={{ textAlign: 'left', fontSize: '.8rem' }}>
                                <Label style={{ fontWeight: 'bold' }}>接收号码：</Label>
                                {phones}
                            </Col>
                            <Col style={{ textAlign: 'left', fontSize: '.8rem' }}>
                                {content}
                            </Col>
                        </FormGroup>
                    </Card>
                </CardGroup>

                <FormGroup>
                    <button className="btn btn-success" onClick={(ev) => this.send(ev)}>发送</button>
                </FormGroup>

            </AdminLayout>
        )

    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        config: state.adminData.config
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getConfig: () => dispatch(getConfigAction())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SendSMS);