
import React from 'react';
import {
    Container,
    Col,
    Row
} from 'reactstrap';
import { Link } from "react-router-dom";


class NullData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showElem: window.sessionStorage.role == 'admin'
        }
    }

    renderButton(history) {
        return (
            <div className="text-center">
                <Link to={history} className="btn btn-secondary">
                    <i className="iconfont">&#xe6a9;</i>
                    Go Back
				</Link>{' '}
                <Link to="/" className="btn btn-primary">
                    <i className="iconfont">&#xe642;</i>
                    Go Home
				</Link>
            </div>)
    }
    render() {

        return (
            <Container className="user-center">
                <Row>
                    <Col md={12}>
                        <div className="error-container">
                            <div className="well">
                                <h2 className="grey lighter smaller" style={{ color: "#f00" }}>
                                    没有数据
                                </h2>
                                <hr />
                                <h3 className="lighter smaller">没有找到您需要的数据，请稍后再试。</h3>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default NullData