import React from 'react';
import { connect } from 'react-redux';

import {
    Label,
    FormGroup,
    Table,
    Input, Alert,
    Col, Button, Modal, ModalBody, ModalHeader, ModalFooter
} from 'reactstrap';
import Util from '../../utils/Util'
import NullData from '../../components/NullData'
import { getLogsAction } from './actions'
import AdminLayout from '../../containers/Admin/AdminLayout'
import { LANGUAGE } from '../../config/Language';
import { JsonView } from 'json-view-for-react'

class SystemLogs extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showElem: window.sessionStorage.role == 'admin',
            alerts: [],
            filter: { page: 1, size: 10 },
            modal: false,
            errorShow: false,
            errorMessage: ""
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        try {
            for (let i in nextProps) {
                if (nextProps[i] && nextProps[i].statusCode) {
                    if (nextProps[i].statusCode == 401) {
                        this.props.history.push("/logout")
                    } else if (nextProps[i].statusCode !== 200 && nextState == this.state) {
                       var alerts = this.state.alerts
                        alerts.push({
                            modal: true,
                            message: nextProps[i].message,
                            type: "danger"
                        })
                        this.setState({
                            alerts: alerts
                        })
                    }
                    
                }
            }
        } catch (e) { }
        
        return true;
    } 
    renderAlert(alert, id) {
        return (
            <Alert color={alert.type} isOpen={alert.modal} fade={false} key={id} toggle={e => this.onDismiss(e, id)}>
                {alert.message}
            </Alert>
        )
    }
    onDismiss(e, id) {
        let alerts = this.state.alerts
        alerts[id].modal = false

        this.setState({
            alerts: alerts
        })
    }


    componentDidMount() {
        this.props.getLogs(this.state.filter)
    }

    toggle(ev, data) {
        this.setState({
            modal: !this.state.modal,
            ModalTitle: "详细信息",
            modalBody: <JsonView obj={data} showLineNumbers />
        })
    }

    renderLogs(data, key) {
        return (
            <tr key={key}>
                <th scope="row">{Number(key) + 1}</th>
                <td>{data.username}</td>
                <td>{Util.s2ss(data.created_at, 'HH:mm:ss', 'YY/MM/DD')}</td>
                <td >{data.country}</td>
                <td >{data.area}</td>
                <td>{LANGUAGE[data.source]}</td>
                <td>{LANGUAGE[data.type]}</td>
                <td >{LANGUAGE[data.level]}</td>
                <td><span className={`text-${data.level}`}>{data.reason}</span></td>
                <td><Button color="link" onClick={ev => this.toggle(ev, data)}><i className="iconfont iconjson"></i></Button></td>
            </tr >
        )
    }

    renderCurrentPager() {
        return (
            <div className="d-loader-box">
                <div className="loader-15"></div>
            </div>
        )
    }

    renderPagination(pagination, currentPager) {
        let nextDisabled = ""
        if (pagination.current === pagination.page) {
            nextDisabled = "disabled"
        }

        let prevDisabled = ""
        if (pagination.current === 1) {
            prevDisabled = "disabled"
        }

        return (
            <ul className="pagination justify-content-center">
                <li className={`page-item ${prevDisabled}`}>
                    <button className="page-link" onClick={(ev) => this.pager(ev, 1)}>First</button>
                </li>

                <li className={`page-item ${prevDisabled}`}>
                    <button className="page-link" onClick={(ev) => this.pager(ev, this.state.filter.page - 1)}>Prev</button>
                </li>

                <li className="page-item active">
                    <span className="page-link">
                        {currentPager}
                        <span className="sr-only">(current)</span>
                    </span>
                </li>

                <li className={`page-item ${nextDisabled}`}>
                    <button className={`page-link ${nextDisabled}`} onClick={(ev) => this.pager(ev, this.state.filter.page + 1)}>Next</button>
                </li>

                <li className={`page-item ${nextDisabled}`}>
                    <button className={`page-link`} onClick={(ev) => this.pager(ev, pagination.page)}>Last</button>
                </li>
            </ul>
        )
    }

    renderOption(props) {
        return (
            <option key={props.type} value={props.type}>
                {LANGUAGE[props.type]}
            </option>
        )
    }

    handleFilter(e) {
        let filter = this.state.filter
        filter[e.target.name] = e.target.value

        this.setState({
            filter: filter
        })
    }

    search(e) {
        let filter = this.state.filter
        filter.page = 1
        this.setState({ filter: filter })
        this.props.getLogs(filter)
    }

    pager(ev, page) {
        if (page > 0) {
            let filter = this.state.filter
            filter.page = page
            this.setState({ filter: filter })
            this.props.getLogs(filter)
        }
    }

    render() {

        try {
            var logs, pagination, type, source

            if (this.props.logs && Object.keys(this.props.logs.data.rows).length) {
                let currentPager = `${this.props.logs.data.pagination.current} of ${this.props.logs.data.pagination.page}`
                if (this.props.logs.data.pagination.current !== this.state.filter.page) {
                    currentPager = this.renderCurrentPager()
                }
                pagination = this.renderPagination(this.props.logs.data.pagination, currentPager)
                logs = Object.keys(this.props.logs.data.rows).map(key => this.renderLogs(this.props.logs.data.rows[key], key))
            }

            type = Object.keys(this.props.logs.data.filter.type).map(key => this.renderOption(this.props.logs.data.filter.type[key], key))
            source = Object.keys(this.props.logs.data.filter.source).map(key => this.renderOption(this.props.logs.data.filter.source[key], key))

        } catch (e) { }

        
        try {
            var alerts = Object.keys(this.state.alerts).map(key => this.renderAlert(this.state.alerts[key], key))
        } catch (e) { }

        return (
            <AdminLayout modal={this.state.modal} props={this.props} className="logs">
                <Modal isOpen={this.state.modal} toggle={(ev) => this.toggle(ev)}>
                    <ModalHeader toggle={(ev) => this.toggle(ev)} className="text-danger">{this.state.ModalTitle}</ModalHeader>
                    <ModalBody id="modal-body">{this.state.modalBody}</ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={(ev) => this.toggle(ev)}>好的</Button>
                    </ModalFooter>
                </Modal>
                <FormGroup row className="search-box">
                    <Label for="examplePassword">行数</Label>
                    {/* <Col sm={1}> */}
                    <Input bsSize="sm" type="select" name="size" onChange={(ev) => this.handleFilter(ev)} defaultValue={this.state.filter.size}>
                        <option>10</option>
                        <option>15</option>
                        <option>20</option>
                        <option>25</option>
                        <option>30</option>
                    </Input>
                    {/* </Col> */}
                    <Label for="exampleSelect">级别</Label>
                    {/* <Col sm={1}> */}
                    <Input bsSize="sm" type="select" name="level" onChange={(ev) => this.handleFilter(ev)}>
                        <option value="all">{LANGUAGE['all']}</option>
                        <option value="error">{LANGUAGE['error']}</option>
                        <option value="warning">{LANGUAGE['warning']}</option>
                        <option value="info">{LANGUAGE['info']}</option>
                    </Input>
                    {/* </Col> */}
                    <Label for="examplePassword">类别</Label>
                    {/* <Col sm={1}> */}
                    <Input bsSize="sm" type="select" name="type" onChange={(ev) => this.handleFilter(ev)}>
                        <option value="all">{LANGUAGE['all']}</option>
                        {type}
                    </Input>
                    {/* </Col> */}
                    <Label for="examplePassword">来源</Label>
                    {/* <Col sm={1}> */}
                    <Input bsSize="sm" type="select" name="source" onChange={(ev) => this.handleFilter(ev)}>
                        <option value="all">{LANGUAGE['all']}</option>
                        {source}
                    </Input>
                    {/* </Col> */}
                    <Label for="examplePassword">用户名</Label>
                    {/* <Col sm={1}> */}
                    <Input bsSize="sm" type="text" name="user" onChange={(ev) => this.handleFilter(ev)} />
                    {/* </Col> */}
                    <Label for="examplePassword">扩展信息</Label>
                    {/* <Col sm={1}> */}
                    <Input bsSize="sm" type="text" name="ext" onChange={(ev) => this.handleFilter(ev)} />
                    {/* </Col>
                    <Col sm={1}> */}
                    <button className="btn btn-secondary" onClick={(ev) => this.search(ev)}>搜索</button>
                    {/* </Col> */}
                </FormGroup>

                <Table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>用户</th>
                            <th>时间</th>
                            <th>地区</th>
                            <th>运营商</th>
                            <th>来源</th>
                            <th>类别</th>
                            <th>级别</th>
                            <th>状态</th>
                            <th>操作</th>
                        </tr>
                    </thead>
                    <tbody>
                        {logs}
                    </tbody>
                </Table>
                <Col style={{ textAlign: "center" }}>
                    {pagination}
                </Col>

            </AdminLayout>
        )

    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        logs: state.adminData.logs
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getLogs: (filter) => dispatch(getLogsAction(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SystemLogs);