import moment from 'moment';

const methods = {
    s2s(sec) {
        if (sec === 0) {
            return '0秒';
        } else if (sec === -1) {
            return '永久'
        }

        var second = 1,
            minute = second * 60,
            hour = minute * 60,
            day = hour * 24,
            week = day * 7,
            Month = day * 30,
            year = day * 365,
            str = ""

        let y = Math.floor(sec / year)
        if (y > 0) {
            str += `${y}年`
        }

        let M = Math.floor(sec % year / Month)
        if (M > 0) {
            str += `${M}个月`
        }

        let w = Math.floor(sec % year % Month / week)
        if (w > 0) {
            str += `${w}周`
        }

        let d = Math.floor(sec % year % Month % week / day)
        if (d > 0) {
            str += `${d}天`
        }

        let h = Math.floor(sec % year % Month % week % day / hour)
        if (h > 0) {
            str += `${h}小时`
        }

        let m = Math.floor(sec % year % Month % week % day % hour / minute)
        if (m > 0) {
            str += `${m}分`
        }

        let s = sec % year % Month % week % day % hour % minute
        if (s > 0) {
            str += `${s}秒`
        }

        return str
    },
    s2ss(sec, lformat, fformat) {
        if (typeof sec == "number") sec *= 1000
        if (!lformat) lformat = "HH:mm:ss"
        if (!fformat) fformat = "YYYY/MM/DD"

        let date = moment(sec).format('YYYY/MM/DD')
        if (date == moment().format('YYYY/MM/DD')) {
            return moment(sec).format(`今天 ${lformat}`)
        }

        if (date == moment().subtract(1, 'days').format('YYYY/MM/DD')) {
            return moment(sec).format(`昨天 ${lformat}`)
        }

        if (date == moment().subtract(2, 'days').format('YYYY/MM/DD')) {
            return moment(sec).format(`前天 ${lformat}`)
        }

        return moment(sec).format(`${fformat} ${lformat}`)

    },
    space(value) {
        if (null == value || value == '') {
            return "0 Bytes";
        }
        var unitArr = new Array("Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB");
        var index = 0,
            srcsize = parseFloat(value);
        index = Math.floor(Math.log(srcsize) / Math.log(1024));
        var size = srcsize / Math.pow(1024, index);
        //  保留的小数位数
        size = size.toFixed(2);
        return size + unitArr[index];
    },

}

export default methods