import React from 'react';
import { connect } from 'react-redux';
import { getMessageAction, readedAction, loginAction } from './actions'
import {
    Container,
    Row,
    Col,
    Card,
    FormGroup,
    Input,
    Button,
    Alert,
} from 'reactstrap'
import Util from '../../utils/Util'
import ErrorPage from '../../components/ErrorPage';
import './Message.scss';
class Message extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            code: null,
            hasError: false
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        try {
            if (nextProps.loginData.statusCode == 400) {
                this.setState({
                    hasError: true
                })
            }
        } catch (e) { }
        try {
            if (nextProps.message.data.readed === "N" && undefined == nextProps.readed) {
                setTimeout(() => {
                    this.props.readedMessage(this.props.match.params.mid, this.props.match.params.token)
                }, 3000)
            }
        } catch (e) { }
        return true;
    }
    renderAlert(alert, id) {
        return (
            <Alert color={alert.type} isOpen={alert.modal} fade={false} key={id} toggle={e => this.onDismiss(e, id)}>
                {alert.message}
            </Alert>
        )
    }
    onDismiss(e, id) {
        let alerts = this.state.alerts
        alerts[id].modal = false

        this.setState({
            alerts: alerts
        })
    }


    // render后
    componentDidMount() {
        if (Object.keys(this.props.match.params).length > 1 && this.state.hasError === false)
            this.props.getMessage(this.props.match.params.mid, this.props.match.params.token)
    }

    passwordHandler(ev) {
        this.setState({
            code: ev.target.value
        })
    }

    login(e) {
        if (this.state.code) {
            this.props.login(this.props.match.params.mid, this.props.match.params.token, this.state.code)
        }
    }

    keyDown(ev) {
        if (ev.nativeEvent.keyCode === 13) {
            ev.preventDefault()
            this.login(ev)
        }
    }

    renderViewInput() {
        return (
            <form>
                <FormGroup row onKeyPress={(ev) => this.keyDown(ev)}>
                    <Col>
                        <label htmlFor="inputPassword" className="sr-only">Password</label>
                        <Input type="password" id="inputPassword" placeholder="密码" name="password" onChange={(ev) => this.passwordHandler(ev)} required autoComplete="off" maxLength="6" />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col>
                        <Button color="primary" className="btn btn-lg btn-primary btn-block" onClick={(ev) => this.login(ev)}>确定</Button>
                    </Col>
                </FormGroup>
            </form>
        )
    }

    render() {
        try {
            var title, message
            var code = 200
            if (!this.props.message) {
                return (<div></div>)
            }

            if (this.props.message.statusCode !== 200) {
                code = this.props.message.statusCode
                if (this.props.message.statusCode === 423) {
                    title = '请输入密码'
                    message = this.renderViewInput()
                } else {
                    title = `${this.props.message.statusCode} ${this.props.message.error || "Error"}`
                    message = `${this.props.message.message}`
                }
            }

            var props = this.props.message.data
            try {
                if (this.props.loginData.statusCode == 400) {
                    title = this.props.loginData.message
                } else if (this.props.loginData.statusCode == 200) {
                    props = this.props.loginData.data
                    code = this.props.loginData.statusCode
                }
            } catch (e) { }

            var currentFn = props.fn.substring(0, 4),
                currentReceiver = props.receiver.substring(0, 11),
                currentSender = props.sender.substring(0, 11),
                currentReceiveAt = Util.s2ss(props.receive_at),
                currentContent = props.content,
                currentSim = props.sim,
                currentApn = props.apn,
                currentLocation = props.location,
                currentMark = props.mark,
                currentName = props.name,
                currentAvatar = `${props.static}${props.avatar}`
        } catch (e) { }
        return (
            <Container className="wx-message">
                {code === 200 ? (
                    <div>
                        <Card className="wx-message-header">
                            <Col md={12}>
                                <Col className="avatar fl">
                                    <img src={currentAvatar} alt={currentSender} />
                                </Col>
                                <Col className="fl base-info">
                                    <Row>
                                        <dt>接收:</dt>
                                        <dd className={`sim sim-${currentSim}`}>{currentName}<span>&lt;{currentReceiver}&gt;</span></dd>

                                    </Row>
                                    <Row>
                                        <dt>发送:</dt>
                                        <dd>
                                            {currentFn}
                                            <span className={currentFn === currentSender ? "hide" : ""}>&lt;{currentSender}&gt;</span>
                                        </dd>
                                        <a href={`https://www.baidu.com/s?wd=${currentSender}`} rel="noopener noreferrer" target="_blank" className="btn-search">
                                            <i className="iconfont">&#xe615;</i>
                                        </a>
                                    </Row>
                                    <Row>
                                        <dt>时间:</dt>
                                        <dd>{currentReceiveAt}</dd>
                                    </Row>
                                    <Row>
                                        <dt>标签:</dt>
                                        <dd className="apn-tag cell-mark-detail">{currentApn}</dd>
                                        <div className="cell-mark">
                                            <dd className="bg-warning cell-mark-detail" >{currentMark}</dd>
                                            <dd className="bg-warning cell-mark-detail" >{currentLocation}</dd>
                                        </div>
                                    </Row>
                                </Col>
                            </Col>

                        </Card>
                        <Col className="message-body">
                            <div>{currentContent}</div>
                        </Col>
                    </div>
                ) : <ErrorPage title={title} message={message} />}
            </Container>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        message: state.weixinData.message,
        readed: state.weixinData.readed,
        loginData: state.weixinData.login
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getMessage: (mid, token) => dispatch(getMessageAction(mid, token)),
        readedMessage: (mid, token) => dispatch(readedAction(mid, token)),
        login: (mid, token, code) => dispatch(loginAction(mid, token, code))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Message);