import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { getContactListAction, saveContactAction, newContactAction, uploadAvatarAction, shareContactAction, deleteContactAction } from './actions'
import {
    Container,
    Card,
    CardBody,
    CardTitle,
    Table,
    Input,
    Button,
    Media,
    Label,
    FormGroup,
    Col, Modal, ModalHeader, ModalBody, ModalFooter,
    Alert
} from 'reactstrap';
import Topbar from '../../components/Topbar';
import NullData from '../../components/NullData';
import unAvatar from '../../assets/img/avatar.png'
import './Contact.scss'
import _ from 'loadsh'

class Contact extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            selectContacts: [],
            modal: false,
            newContactInfo: {},
            showElem: window.sessionStorage.role == 'admin',
            alert: {
                modal: false,
                message: "",
                type: "danger"
            },
            modalMessage: "",
            avatarUpdating: false,
            filter: {
                size: 15,
                page: 1,
                shared: - 1
            },
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        try {
            for (let i in nextProps) {
                if (nextProps[i] && nextProps[i].statusCode) {
                    if (nextProps[i].statusCode == 401) {
                        this.props.history.push("/logout")
                    } else if (nextProps[i].statusCode !== 200 && nextState == this.state) {
                       var alerts = this.state.alerts
                        alerts.push({
                            modal: true,
                            message: nextProps[i].message,
                            type: "danger"
                        })
                        this.setState({
                            alerts: alerts
                        })
                    }
                    
                }
            }
        } catch (e) { }

        try {

            if (nextProps.create.statusCode == 200 && nextState == this.state && nextProps.create != this.props.create) {
                this.setState({
                    modal: false
                })
                this.props.getContactList(this.state.filter)
            }

        } catch (e) { }

        try {
            if (this.props != nextProps) {
                if (nextProps.modify.data.id == nextState.currentContact.id) {
                    this.setState({ modal: false })
                }
                if (this.props.modify != nextProps.modify) {
                    this.props.getContactList(this.state.currentPage, this.state.filter)
                }
            }

        } catch (e) { }


        try {
            if (this.props != nextProps) {
                if (this.props.shared != nextProps.shared) {
                    this.props.getContactList(this.state.currentPage, this.state.filter)
                }
            }
        } catch (e) { }

        try {
            if (this.props != nextProps) {
                if (this.props.delete != nextProps.delete) {
                    this.props.getContactList(this.state.currentPage, this.state.filter)
                }
            }
        } catch (e) { }

        try {
            if (this.props.avatar != nextProps.avatar) {
                let currentContact = {}
                if (this.state.currentContact && Object.keys(this.state.currentContact).length) {
                    currentContact = this.state.currentContact
                }
                currentContact.static = nextProps.avatar.data.static
                currentContact.avatar = nextProps.avatar.data.avatar
                this.setState({
                    newContactInfo: currentContact,
                    currentContact: currentContact,
                    avatarUpdating: false,
                    modalMessage: ""
                })
            }
        } catch (e) { }

        return true;
    } 
    renderAlert(alert, id) {
        return (
            <Alert color={alert.type} isOpen={alert.modal} fade={false} key={id} toggle={e => this.onDismiss(e, id)}>
                {alert.message}
            </Alert>
        )
    }
    onDismiss(e, id) {
        let alerts = this.state.alerts
        alerts[id].modal = false

        this.setState({
            alerts: alerts
        })
    }



    componentDidMount() {
        this.props.getContactList(this.state.currentPage, this.state.filter)
    }

    selectContact(ev, id) {
        let selected = this.state.selectContacts
        if (ev.target.checked == true) {
            selected.push(id)
        } else {
            for (let k in selected) {
                if (selected[k] == id) {
                    selected.splice(k, 1)
                }
            }
        }
        this.setState({
            selectContacts: selected
        })
    }

    shareContact(ev, status) {
        this.props.shareContact(this.state.selectContacts, status)
    }

    deleteContact(ev, id) {
        let ids = this.state.selectContacts
        if (id) {
            ids = [id]
        }
        this.props.deleteContact(ids)
    }

    renderContactRow(data, key) {
        let share = '&#xe621;'
        let shareClass = ""
        if (data.shared == 1) {
            share = '&#xe669;'
            shareClass = "text-success"
        } else if (data.shared == 2) {
            share = '&#xe665;'
            shareClass = "text-warning"
        } else if (data.shared == 3 && sessionStorage.uid == 1) {
            shareClass = "text-danger"
        }

        return (
            <tr key={data.id}>
                <td><Input type="checkbox" onClick={(ev) => this.selectContact(ev, data.id)} /></td>
                <td className="avatar"><img src={`${data.static}${data.avatar}`} alt={data.fn} /></td>
                <td>{data.cell}</td>
                <td>{data.fn}</td>
                <td>{data.org}</td>
                <td>{data.location}</td>
                <td>{data.mark}</td>
                <td><i className={`iconfont ${shareClass}`} dangerouslySetInnerHTML={{ __html: share }}></i></td>
                <td><Button color="link" onClick={(ev) => this.toggle(ev, 2, data)}>修改</Button>{' '}
                    <Button color="link" onClick={(ev) => this.deleteContact(ev, data.id)}>删除</Button></td>
            </tr>
        )
    }

    pager(ev, page) {
        if (page > 0) {
            let filter = this.state.filter
            filter.page = page
            this.setState({ filter: filter })
            this.props.getContactList(filter)
        }
    }

    renderPagination(pagination, currentPager) {
        let nextDisabled = ""
        if (pagination.current === pagination.page) {
            nextDisabled = "disabled"
        }

        let prevDisabled = ""
        if (pagination.current === 1) {
            prevDisabled = "disabled"
        }

        return (
            <ul className="pagination justify-content-center">
                <li className={`page-item ${prevDisabled}`}>
                    <button className="page-link" onClick={(ev) => this.pager(ev, 1)}>First</button>
                </li>

                <li className={`page-item ${prevDisabled}`}>
                    <button className="page-link" onClick={(ev) => this.pager(ev, this.state.filter.page - 1)}>Prev</button>
                </li>

                <li className="page-item active">
                    <span className="page-link">
                        {currentPager}
                        <span className="sr-only">(current)</span>
                    </span>
                </li>

                <li className={`page-item ${nextDisabled}`}>
                    <button className={`page-link ${nextDisabled}`} onClick={(ev) => this.pager(ev, this.state.filter.page + 1)}>Next</button>
                </li>

                <li className={`page-item ${nextDisabled}`}>
                    <button className={`page-link`} onClick={(ev) => this.pager(ev, pagination.page)}>Last</button>
                </li>
            </ul>
        )
    }

    renderCurrentPager() {
        return (
            <div className="d-loader-box">
                <div className="loader-15"></div>
            </div>
        )
    }

    toggle(ev, flag, data) {
        let title = '新建联系人'
        if (flag === 2) {
            title = `修改联系人${data.fn}`
        }
        this.setState({ modalMessage: "" })
        try {
            var newData = Object.assign({}, { avatar: "", static: "" }, {
                id: data.id,
                fn: data.fn,
                cell: data.cell,
                static: data.static,
                avatar: data.avatar,
                shared: data.shared
            })
        } catch (e) { }
        this.setState({
            modal: !this.state.modal,
            modalTitle: title,
            currentContact: newData,
            flag: flag,
            newContactInfo: {}
        })
    }

    modalChange(ev) {
        let data = this.state.newContactInfo
        switch (ev.target.name) {
            case "cell":
                data.cell = ev.target.value
                break
            case "fn":
                data.fn = ev.target.value
                break
            case "share":
                data.shared = ev.target.value
                break
            case "avatar":
                data.avatar = ev.target.value
                break
            default:
                data = {}
        }
        if (this.state.flag == 2)
            data.id = this.state.currentContact.id
        this.setState({
            newContactInfo: data
        })
    }

    saveContact(ev) {
        var data = this.state.newContactInfo
        if (Object.keys(data).length === 0) {
            this.setState({
                modal: !this.state.modal
            })
            return;
        }

        delete data.static
        if (this.state.flag === 1) {
            if (_.trim(data.cell) == "") {
                this.setState({
                    modalMessage: '请填写电话'
                })
                return;
            }
            if (_.trim(data.fn) == "") {
                this.setState({
                    modalMessage: '请填写姓名'
                })
                return;
            }
            if (typeof data.shared == "undefined") {
                data.shared = 0
            }

            this.props.newContact(data)
        } else if (this.state.flag === 2) {
            if (data.id != undefined) {
                if (typeof data.cell != "undefined" && _.trim(data.cell) == "") {
                    this.setState({
                        modalMessage: '请填写电话'
                    })
                    return;
                }
                if (typeof data.fn != "undefined" && _.trim(data.fn) == "") {
                    this.setState({
                        modalMessage: '请填写姓名'
                    })
                    return;
                }
                this.props.saveContact(data)
            }
        }
    }

    renderShareBtn() {
        var shared = 0
        try {
            shared = this.state.currentContact.shared
        } catch (e) { }

        if (sessionStorage.uid == 1) {
            return (
                <span>
                    <input type="radio" value="2" name="share" defaultChecked={shared == 2} onClick={(ev) => this.modalChange(ev)} />{' '} 待审核{'  '}
                    <input type="radio" value="0" name="share" defaultChecked={shared == 0} onClick={(ev) => this.modalChange(ev)} />{' '} 否{' '}
                    <input type="radio" value="1" name="share" defaultChecked={shared == 1} onClick={(ev) => this.modalChange(ev)} />{' '} 通过{' '}
                    <input type="radio" value="3" name="share" defaultChecked={shared == 3} onClick={(ev) => this.modalChange(ev)} />{' '} 未传头像{' '}
                </span>
            )
        } else {
            return (
                <span>
                    <input type="radio" value="2" name="share" defaultChecked={shared !== 0} onClick={(ev) => this.modalChange(ev)} />{' '} 是{'  '}
                    <input type="radio" value="0" name="share" defaultChecked={shared == 0} onClick={(ev) => this.modalChange(ev)} />{' '} 否{' '}
                </span>
            )
        }
    }

    uploadAvatar(e) {
        let type = e.target.files[0].type.split('/')

        if (type[0] != 'image') {
            this.setState({
                modalMessage: '请上传图片文件'
            })
            return;
        }

        if (_.indexOf(['jpeg', 'jpg', 'png'], type[1]) == -1) {
            this.setState({
                modalMessage: '头像仅支持jpg和png格式'
            })
            return;
        }

        if (e.target.files[0].size > 500000) {
            this.setState({
                modalMessage: '图片不能大于500k'
            })
            return;
        }

        this.setState({ avatarUpdating: true })
        const file = e.target.files[0];
        const fr = new FileReader();
        fr.readAsDataURL(file);
        let fileContent = "data:";
        fr.onload = () => {
            fileContent = fr.result;
        };

        setTimeout(() => {
            this.props.uploadAvatar(fileContent.split(':')[1])
        }, 100);
    }

    handleFilter(e) {
        let filter = this.state.filter
        filter[e.target.name] = e.target.value

        this.setState({
            filter: filter
        })
    }

    search(e) {
        let filter = this.state.filter
        filter.page = 1
        this.setState({ filter: filter })
        this.props.getContactList(filter)
    }

    renderShareBtnGroup() {
        if (sessionStorage.uid == 1) {
            return (<span>
                <Button color="primary" size="sm" onClick={(ev) => this.toggle(ev, 1)}>添加</Button>{' '}
                <Button color="danger" size="sm" onClick={(ev) => this.deleteContact()}>删除</Button>{' '}
                <Button color="success" size="sm" onClick={(ev) => this.shareContact(ev, 2)}>共享</Button>{' '}
                <Button color="secondary" size="sm" onClick={(ev) => this.shareContact(ev, 0)}>取消</Button>{' '}
                <Button color="warning" size="sm" onClick={(ev) => this.shareContact(ev, 1)}>审核</Button>
            </span>)
        } else {
            return (<span>
                <Button color="primary" size="sm" onClick={(ev) => this.toggle(ev, 1)}>添加</Button>{' '}
                <Button color="danger" size="sm" onClick={(ev) => this.deleteContact()}>删除</Button>{' '}
                <Button color="success" size="sm" onClick={(ev) => this.shareContact(ev, 2)}>共享</Button>{' '}
                <Button color="secondary" size="sm" onClick={(ev) => this.shareContact(ev, 0)}>取消</Button>
            </span>)
        }
    }

    render() {
        var contacts, pagination, nullData

        try {
            var closeBtn = <button className="close" onClick={(ev) => this.toggle(ev)}>&times;</button>
            var applyBtn = this.renderShareBtn()
            var shareBtnGroup = this.renderShareBtnGroup()

            if (this.props.list && Object.keys(this.props.list).length) {
                var currentPager = `${this.props.list.data.pagination.current} of ${this.props.list.data.pagination.page}`
                if (this.props.list.data.pagination.current !== this.state.filter.page) {
                    currentPager = this.renderCurrentPager()
                }
                if (this.props.list.data.rows[0]) {
                    pagination = this.renderPagination(this.props.list.data.pagination, currentPager)
                    contacts = Object.keys(this.props.list.data.rows).map(key => this.renderContactRow(this.props.list.data.rows[key], key))
                } else {
                    nullData = true
                }
            }

            if (this.state.currentContact) {
                var modalId = this.state.currentContact.id
                var modalFn = this.state.currentContact.fn
                var modalCell = this.state.currentContact.cell
                var modalAvatar = `${this.state.currentContact.static}${this.state.currentContact.avatar}`
            }
        } catch (e) { }

        return (<div>
            <Helmet>
                <title>{`通讯录 - ${process.env.REACT_APP_NAME}`}</title>
            </Helmet>
            <Topbar modal={this.state.modal} props={this.props} />
            <Container className="contacts">
                <Alert color={this.state.alert.type} isOpen={this.state.alert.modal} >
                    {this.state.alert.message}
                </Alert>
                <Card>
                    <CardBody>
                        <CardTitle><i className="iconfont">&#xe720;</i>通讯录</CardTitle>

                        <FormGroup row className="search-box">
                            <Label for="examplePassword">行数</Label>
                            {/* <Col sm={1}> */}
                            <Input bsSize="sm" type="select" name="size" onChange={(ev) => this.handleFilter(ev)} defaultValue={this.state.filter.size}>
                                <option>10</option>
                                <option>15</option>
                                <option>20</option>
                                <option>25</option>
                                <option>30</option>
                            </Input>
                            {/* </Col> */}
                            <Label for="exampleSelect">共享</Label>
                            {/* <Col sm={2}> */}
                            <Input bsSize="sm" type="select" name="shared" onChange={(ev) => this.handleFilter(ev)}>
                                <option value="-1">全部</option>
                                <option value="1">是</option>
                                <option value="0">否</option>
                                {this.state.showElem ? (<option value="2">审核中</option>) : ''}
                                {this.state.showElem ? (<option value="3">待维护</option>) : ''}
                            </Input>
                            {/* </Col> */}
                            <Label for="examplePassword">扩展信息</Label>
                            {/* <Col sm={2}> */}
                            <Input bsSize="sm" type="text" name="ext" onChange={(ev) => this.handleFilter(ev)} />
                            {/* </Col>
                            <Col sm={1}> */}
                            <button className="btn btn-secondary" onClick={(ev) => this.search(ev)}>搜索</button>
                            {/* </Col> */}
                        </FormGroup>

                        <CardTitle>
                            {/* <Col> */}
                            {shareBtnGroup}
                            {/* </Col> */}
                        </CardTitle>
                        {!nullData ? (<div>
                            <Table >
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>头像</th>
                                        <th>号码</th>
                                        <th>姓名</th>
                                        <th>单位</th>
                                        <th>归属地</th>
                                        <th>标签</th>
                                        <th>共享</th>
                                        <th>操作</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {contacts}
                                </tbody>
                            </Table>
                            <Col style={{ textAlign: "center" }}>
                                {pagination}
                            </Col>
                        </div>) : <NullData />}
                    </CardBody>
                </Card>

                <Modal isOpen={this.state.modal} toggle={(ev) => this.toggle(ev)} style={{ maxWidth: 650 }} className="contact-modal">
                    <ModalHeader toggle={(ev) => this.toggle(ev)} close={closeBtn}>{this.state.modalTitle}</ModalHeader>
                    <ModalBody>
                        <Media>
                            <Media left>
                                <Col md={9} className="avatar">
                                    {this.state.avatarUpdating ? (<div className="loader-19 d-loader-modal" style={{ color: 'red' }}></div>) :
                                        <Media src={modalAvatar || unAvatar} alt={modalFn} />
                                    }
                                </Col>
                            </Media>
                            <Media body>
                                <Col md={12}>
                                    <FormGroup row>
                                        <Label for="exampleZip" sm={2}>头像</Label>
                                        <Col sm={10}>
                                            <Input type="file" onChange={(ev) => this.uploadAvatar(ev)}></Input>
                                        </Col>
                                    </FormGroup>

                                    <FormGroup row>
                                        <Label for="exampleZip" sm={2}>姓名</Label>
                                        <Col sm={10}>
                                            <Input type="text" name="fn" defaultValue={modalFn} onChange={(ev) => this.modalChange(ev)}></Input>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="exampleZip" sm={2}>电话</Label>
                                        <Col sm={10}>
                                            <Input type="text" name="cell" defaultValue={modalCell} onChange={(ev) => this.modalChange(ev)}></Input>
                                        </Col>
                                        <Input type="hidden" defaultValue={modalId} ></Input>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="exampleZip" sm={2}>共享</Label>
                                        <Col sm={10}>
                                            <Label sm={12}>
                                                {applyBtn}
                                            </Label>
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Media>
                        </Media>
                    </ModalBody>
                    <ModalFooter>
                        <span className="text-danger">{this.state.modalMessage}</span>
                        <Button color="primary" disabled={this.state.avatarUpdating} onClick={(ev) => this.saveContact(ev)}>保存</Button>{' '}
                        <Button color="secondary" onClick={(ev) => this.toggle(ev)}>取消</Button>
                    </ModalFooter>
                </Modal>

            </Container>
        </div >)
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        list: state.contactData.list,
        create: state.contactData.create,
        modify: state.contactData.modify,
        avatar: state.contactData.avatar,
        shared: state.contactData.share,
        delete: state.contactData.delete
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getContactList: (filter) => dispatch(getContactListAction(filter)),
        newContact: (data) => dispatch(newContactAction(data)),
        saveContact: (data) => dispatch(saveContactAction(data)),
        uploadAvatar: (data) => dispatch(uploadAvatarAction(data)),
        shareContact: (data, status) => dispatch(shareContactAction(data, status)),
        deleteContact: (data) => dispatch(deleteContactAction(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);