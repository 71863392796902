import MyFetch from '../../utils/MyFetch';
import {
    LOGS,
    ONLINE,
    OFFLINE,
    UNLOCK,
    LOCKED,
    SETTINGS,
    CHANGESETTINGS,
    DELDENY,
    CREATE_DENY,
    USERS,
    DENY_LIST,
    LOGS_CONSUMER,
    DO_LOGS_CONSUMER,
    DASHBOARD,
    SYSINFO,
    RLOGS,
    SETTINGS_CACHE,
    MAKE_WX_URI
} from './actionTypes';

// =====控制台=====
export const getDashboardAction = () => {
    return (dispatch) => {
        const path = `system/runtime`;
        MyFetch.get(path).then((data) => {
            dispatch(setDashboardAction({
                dashboard: data
            }))
        })
    }
}
export const setDashboardAction = (rows) => ({
    type: DASHBOARD,
    data: rows
})

export const getSystemInfoAction = () => {
    return (dispatch) => {
        const path = `system/info`;
        MyFetch.get(path).then((data) => {
            dispatch(setSysInfoAction({
                systemInfo: data
            }))
        })
    }
}

export const setSysInfoAction = (rows) => ({
    type: SYSINFO,
    data: rows
})


// =====用户管理=====
// 用户列表
export const getUsersAction = (filter) => {
    var query = "state=all"
    if (filter && Object.keys(filter).length > 0) {
        let where = []
        for (let k in filter) {
            where.push(`${k}=${filter[k]}`)
        }
        query = where.join('&')
    }

    return (dispatch) => {
        const path = `admin/user?${query}`;
        MyFetch.get(path).then((data) => {
            dispatch(setUsersListAction({
                users: data
            }))
        })
    }
}

export const setUsersListAction = (rows) => ({
    type: USERS,
    data: rows
})

// 在线用户列表
export const getOnlineAction = (filter) => {
    var query = "type=all"

    if (filter && Object.keys(filter).length > 0) {
        let where = []
        for (let k in filter) {
            where.push(`${k}=${filter[k]}`)
        }
        query = where.join('&')
    }

    return (dispatch) => {
        const path = `admin/user/online?${query}`;
        MyFetch.get(path).then((data) => {
            dispatch(setUserOnlineAction({
                online: data
            }))
        })
    }
}

export const setUserOnlineAction = (rows) => ({
    type: ONLINE,
    data: rows
})

// 用户强制下线
export const killAction = (token) => {
    return (dispatch) => {
        const path = `admin/user?token=${token}`;
        MyFetch.delete(path).then((data) => {
            dispatch(setUserOfflineAction({
                kill: data
            }))
        })
    }
}

export const setUserOfflineAction = (rows) => ({
    type: OFFLINE,
    data: rows
})


// 异常用户列表
export const getLockedAction = (filter) => {
    var query = "user=all"
    if (filter && Object.keys(filter).length > 0) {
        let where = []
        for (let k in filter) {
            where.push(`${k}=${filter[k]}`)
        }
        query = where.join('&')
    }

    return (dispatch) => {
        const path = `admin/user/locked?${query}`;
        MyFetch.get(path).then((data) => {
            dispatch(setLockedAction({
                locked: data
            }))
        })
    }
}

export const setLockedAction = (rows) => ({
    type: LOCKED,
    data: rows
})

// 锁定用户
export const lockAction = (user) => {


}

// 解锁用户
export const unlockAction = (user) => {
    return (dispatch) => {
        const path = `admin/user/locked?user=${user}`;
        MyFetch.delete(path).then((data) => {
            dispatch(setUnlockAction({
                unlock: data
            }))
        })
    }
}

export const setUnlockAction = (rows) => ({
    type: UNLOCK,
    data: rows
})

// =====黑名单=====
// 黑名单列表
export const getDenyAction = (filter) => {
    var query = ""
    if (filter && Object.keys(filter).length > 0) {
        let where = []
        for (let k in filter) {
            where.push(`${k}=${filter[k]}`)
        }
        query = where.join('&')
    }

    return (dispatch) => {
        const path = `admin/deny?${query}`;
        MyFetch.get(path).then((data) => {
            dispatch(setDenyListAction({ deny: data }))
        })
    }
}

export const setDenyListAction = (rows) => ({
    type: DENY_LIST,
    data: rows
})

// 新建黑名单
export const saveDenyAction = (data) => {
    return (dispatch) => {
        const path = `admin/deny`;
        MyFetch.post(path, data).then((data) => {
            dispatch(setCreateDenyAction({
                create: data
            }))
        })
    }
}

export const setCreateDenyAction = (rows) => ({
    type: CREATE_DENY,
    data: rows
})

// 删除黑名单
export const delDenyAction = (id) => {
    return (dispatch) => {
        const path = `admin/deny?id=${id}`;
        MyFetch.delete(path).then((data) => {
            dispatch(setdelDenyAction({
                del: data
            }))
        })
    }
}

export const setdelDenyAction = (rows) => ({
    type: DELDENY,
    data: rows
})

// =====日志管理=====
// 日志列表
export const getRequestLogsAction = (filter) => {
    var query = ""
    if (filter && Object.keys(filter).length > 0) {
        let where = []
        for (let k in filter) {
            where.push(`${k}=${filter[k]}`)
        }
        query = where.join('&')
    }

    return (dispatch) => {
        const path = `admin/logs/request?${query}`;
        MyFetch.get(path).then((data) => {
            dispatch(setRequestLogsAction({
                logs: data
            }))
        })
    }
}

export const setRequestLogsAction = (rows) => ({
    type: RLOGS,
    data: rows
})

// 日志列表
export const getLogsAction = (filter) => {
    var query = ""
    if (filter && Object.keys(filter).length > 0) {
        let where = []
        for (let k in filter) {
            where.push(`${k}=${filter[k]}`)
        }
        query = where.join('&')
    }

    return (dispatch) => {
        const path = `admin/logs/system?${query}`;
        MyFetch.get(path).then((data) => {
            dispatch(setUserLoginLogsAction({
                logs: data
            }))
        })
    }
}

export const setUserLoginLogsAction = (rows) => ({
    type: LOGS,
    data: rows
})

// =====系统设置=====
// 设置信息缓存
export const getSettingsCacheAction = () => {
    return (dispatch) => {
        const path = `admin/settings/cache`;
        MyFetch.get(path).then((data) => {
            dispatch(setSettingsCacheAction({
                settingsCache: data
            }))
        })
    }
}
export const setSettingsCacheAction = (rows) => ({
    type: SETTINGS_CACHE,
    data: rows
})

// 设置信息数据库
export const getSettingsAction = () => {
    return (dispatch) => {
        const path = `admin/settings`;
        MyFetch.get(path).then((data) => {
            dispatch(setSettingsAction({
                settings: data
            }))
        })
    }
}

export const setSettingsAction = (rows) => ({
    type: SETTINGS,
    data: rows
})

// 变更设置
export const changeSettingsAction = (key, value) => {
    var info = []
    if (typeof key == 'object') {
        for (let i in key) {
            let tmp = {}
            tmp[i] = key[i]
            info.push(tmp)
        }
        key = Object.keys(key).join(',')
    } else {
        let data = {}
        data[key] = value
        info.push(data)
    }

    return (dispatch) => {
        const path = `admin/settings/${key}`;
        MyFetch.put(path, info).then((data) => {
            dispatch(setChangeSettingsAction({
                change: data
            }))
        })
    }

}

export const setChangeSettingsAction = (rows) => ({
    type: CHANGESETTINGS,
    data: rows
})


// 日志消费
export const logsConsumerAction = (filter) => {
    var query = ""
    if (filter && Object.keys(filter).length > 0) {
        let where = []
        for (let k in filter) {
            where.push(`${k}=${filter[k]}`)
        }
        query = where.join('&')
    }

    return (dispatch) => {
        const path = `admin/tools/logs/consumer?${query}`;
        MyFetch.get(path).then((data) => {
            dispatch(setLogsConsumerAction({
                logsConsumer: data
            }))
        })
    }
}

export const setLogsConsumerAction = (rows) => ({
    type: LOGS_CONSUMER,
    data: rows
})

export const doConsumerAction = () => {
    return (dispatch) => {
        const path = `/admin/tools/logs/consumer`;
        MyFetch.patch(path).then((data) => {
            dispatch(setDoConsumerActionAction({
                doLogsConsumer: data
            }))
        })
    }
}

export const setDoConsumerActionAction = (rows) => ({
    type: DO_LOGS_CONSUMER,
    data: rows
})