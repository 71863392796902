import MyFetch from '../../utils/MyFetch';
import {
    LIST,
    SAVE,
    NEW,
    AVATAR,
    DELETE, SHARE
} from './actionTypes';

export const getContactListAction = (filter) => {

    var query = ""
    if (filter && Object.keys(filter).length > 0) {
        let where = []
        for (let k in filter) {
            if (k == 'shared' && filter[k] == -1)
                continue
            if (filter[k] != "")
                where.push(`${k}=${filter[k]}`)
        }
        query = where.join('&')
    }

    return (dispatch) => {
        const path = `contact/list?${query}`;
        MyFetch.get(path).then((data) => {
            dispatch(setContactListAction({
                list: data
            }))
        })
    }
}

export const saveContactAction = (info) => {
    delete info.static
    return (dispatch) => {
        const path = `contact/${info.id}`;
        MyFetch.put(path, info).then((data) => {
            dispatch(setSaveAction({
                modify: data
            }))
        })
    }
}

export const shareContactAction = (info, status) => {
    return (dispatch) => {
        const path = `contact/share/${info.join(',')}`;
        MyFetch.put(path, { id: info, shared: status }).then((data) => {
            dispatch(setShareAction({
                share: data
            }))
        })
    }
}

export const deleteContactAction = (info) => {

    return (dispatch) => {
        const path = `contact/${info.join(',')}`;
        MyFetch.delete(path, { id: info }).then((data) => {
            dispatch(setDeleteAction({
                delete: data
            }))
        })
    }
}

export const newContactAction = (info) => {
    return (dispatch) => {
        const path = `contact`;
        MyFetch.post(path, info).then((data) => {
            dispatch(setNewAction({
                create: data
            }))
        })
    }
}

export const uploadAvatarAction = (info) => {
    return (dispatch) => {
        const path = `contact/avatar`;
        MyFetch.post(path, { data: info }).then((data) => {
            dispatch(setAvatarAction({
                avatar: data
            }))
        })
    }
}

export const setContactListAction = (rows) => ({
    type: LIST,
    data: rows
})
export const setNewAction = (rows) => ({
    type: NEW,
    data: rows
})
export const setSaveAction = (rows) => ({
    type: SAVE,
    data: rows
})
export const setAvatarAction = (rows) => ({
    type: AVATAR,
    data: rows
})
export const setDeleteAction = (rows) => ({
    type: DELETE,
    data: rows
})
export const setShareAction = (rows) => ({
    type: SHARE,
    data: rows
})