
import React from 'react';
import { NavLink } from "react-router-dom";
import './Topbar.scss'
const Logo = require('../assets/img/logo.svg')

class Topbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showElem: window.sessionStorage.displayCp == 1
        }
    }

    renderIpTag(v) {
        if (v == 4) {
            return (<i className="iconfont">&#xe600;</i>)
        } else {
            return (<i className="iconfont">&#xe601;</i>)
        }
    }

    render() {
        try {
            var username = window.sessionStorage.name
            // var ipVersion = window.sessionStorage.ipVersion
            // var ipTag = this.renderIpTag(ipVersion)
        } catch (e) { }
        return (
            <header>
                <div className={`mask-layer ${this.props.modal ? 'show' : 'hide'}`}>
                    <div className="loader-20 d-loader-modal"></div>
                </div>
                <div className="topbar">
                    <span className="text-left">
                        <span className="logo"><img src={Logo} alt="logo" /></span>
                        <span>{process.env.REACT_APP_NAME}</span>
                        {/* <span style={{ marginLeft: 10, color: "#ff5722" }}>{ipTag}</span> */}
                    </span>
                    <span className="fr nav">
                        <NavLink exact to="/" className="nav-link"><i className="iconfont">&#xe642;</i>首页</NavLink>
                        <NavLink to="/user" className="nav-link" ><i className="iconfont">&#xe6db;</i>{username}</NavLink>
                        <NavLink to="/contact" className="nav-link"><i className="iconfont">&#xe720;</i>通讯录</NavLink>
                        {this.state.showElem ? (<NavLink to="/admin" className="nav-link"><i className="iconfont">&#xe64f;</i>后台</NavLink>) : null}
                        <NavLink to="/logout"><i className="iconfont">&#xe602;</i>退出</NavLink>
                    </span>
                </div>
            </header>
        );
    }
}

export default Topbar