/* eslint no-shadow:0 */
import React from 'react';
import { connect } from 'react-redux';
import { deleteUserInfoAction } from './actions';
import ErrorPage from '../../components/ErrorPage'

class Register extends React.Component {
    constructor(props) {
        super(props);
    }

    _logout() {
        this.props._logout();
    }

    componentDidMount() {

    }

    render() {
        return (<ErrorPage title="内测提示" message="对不起，系统还在内测阶段，仅限受邀用户使用。请持续关注，谢谢。" history={this.props.history}/>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        loginData: state.loginData,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        _logout: () => dispatch(deleteUserInfoAction()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Register);
