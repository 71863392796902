import React from 'react';
import { connect } from 'react-redux';
import { getSettingsAction, changeSettingsAction } from './actions';
import { LANGUAGE } from '../../config/Language';
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    FormGroup,
    CardGroup,
    CustomInput,
    Input,
    Button,
    Alert
} from 'reactstrap';

import AdminLayout from '../../containers/Admin/AdminLayout'
import './admin.scss';


class Settings extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showElem: window.sessionStorage.role == 'admin',
            alerts: [],
            login_error: {},
            captcha: {},
            token_expire: {}
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        try {

            for (let i in nextProps) {
                if (nextProps[i] && nextProps[i].statusCode) {
                    if (nextProps[i].statusCode == 401) {
                        this.props.history.push("/logout")
                    } else if (nextProps[i].statusCode != 200 && nextState == this.state) {
                        var alerts = this.state.alerts
                        alerts.push({
                            modal: true,
                            message: nextProps[i].message,
                            type: "danger"
                        })
                        this.setState({
                            alerts: alerts
                        })
                    }
                }
            }
        } catch (e) { }

        return true;
    }

    renderAlert(alert, id) {
        return (
            <Alert color={alert.type} isOpen={alert.modal} fade={false} key={id} toggle={e => this.onDismiss(e, id)}>
                {alert.message}
            </Alert>
        )
    }
    onDismiss(e, id) {
        let alerts = this.state.alerts
        alerts[id].modal = false

        this.setState({
            alerts: alerts
        })
    }



    componentDidMount() {
        this.props.getSettings()
    }

    settingsSwitch(e) {
        if (e.target.type == "checkbox")
            this.props.changeSettings(e.target.name, e.target.checked === true ? "Y" : "N")
    }

    settingsSave(e, key) {
        if (Object.keys(this.state[key]).length)
            this.props.changeSettings(this.state[key])
    }

    settingsInput(e) {
        let keys = e.target.name.split('_')
        let storage = `${keys[0]}_${keys[1]}`
        let data = this.state[storage] ? this.state[storage] : {}
        data[e.target.name] = e.target.value
        let newData = {}
        newData[storage] = data
        this.setState(newData)
    }

    rangeOptions(lable, name, defaultValue) {
        if (!this.props.settings) return (<div></div>)
        name = `${name}_unit`
        let defaultUnit = 's'
        try {
            defaultUnit = this.props.settings.data[name]
        } catch (e) { }

        return (
            <div>
                <span className="lable">{lable}</span>
                <Input type="number" defaultValue={defaultValue} name={name} className="token-input" onChange={(ev) => this.settingsInput(ev)}></Input>
                <Input type="select" name={name} defaultValue={defaultUnit} style={{ display: 'unset', width: 'auto' }} onChange={(ev) => this.settingsInput(ev)}>
                    <option value="s">秒</option>
                    <option value="m">分</option>
                    <option value="h">小时</option>
                    <option value="d">天</option>
                    <option value="w">周</option>
                    <option value="M">月</option>
                    <option value="y">年</option>
                </Input>
            </div>
        )
    }

    render() {
        var deny_cell,
            deny_ip,
            weixin_token_unique,
            api_token_unique,
            device_token_unique,
            login_error_threshold,
            captcha_expire_code,
            captcha_expire_image,
            cache_expire_report,
            login_error_expire = [],
            token_expire_api = [],
            token_expire_device = [],
            token_expire_weixin = [],
            settings = {}

        try {

            if (this.props.settings && Object.keys(this.props.settings.data).length) {
                settings = this.props.settings.data
                deny_cell = settings.deny_cell
                deny_ip = settings.deny_ip
                weixin_token_unique = settings.weixin_token_unique
                api_token_unique = settings.api_token_unique
                device_token_unique = settings.device_token_unique
                login_error_threshold = settings.login_error_threshold
                login_error_expire = settings.login_error_expire
                captcha_expire_code = settings.captcha_expire_code
                captcha_expire_image = settings.captcha_expire_image
                token_expire_api = settings.token_expire_api
                token_expire_device = settings.token_expire_device
                token_expire_weixin = settings.token_expire_weixin
                cache_expire_report = settings.cache_expire_report
            }

        } catch (e) { console.log(e) }

        try {
            var alerts = Object.keys(this.state.alerts).map(key => this.renderAlert(this.state.alerts[key], key))
        } catch (e) { }

        
        try {
            var alerts = Object.keys(this.state.alerts).map(key => this.renderAlert(this.state.alerts[key], key))
        } catch (e) { }

        return (
            <AdminLayout modal={this.state.modal} props={this.props} className="settings">
                <div className="alert-group">
                    {alerts}
                </div>
                <CardGroup>
                    <Card>
                        <CardBody>
                            <CardTitle><i className="iconfont iconanquan2"></i>安全设置</CardTitle>
                            <FormGroup>
                                <Col className="switch">
                                    <span className="lable switch"><CustomInput type="switch" id="deny_cell" name="deny_cell" label="手机号黑名单" onClick={(ev) => this.settingsSwitch(ev)} defaultChecked={deny_cell} /></span>
                                </Col>
                                <Col className="switch">
                                    <span className="lable switch"><CustomInput type="switch" id="deny_ip" name="deny_ip" label="IP地址黑名单" onClick={(ev) => this.settingsSwitch(ev)} defaultChecked={deny_ip} /></span>
                                </Col>
                            </FormGroup>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <CardTitle><i className="iconfont iconanquan2"></i>Token唯一性</CardTitle>
                            <FormGroup>
                                <Col className="switch">
                                    <span className="lable switch"><CustomInput type="switch" id="device_token_unique" name="device_token_unique" label="设备Token唯一性" onClick={(ev) => this.settingsSwitch(ev)} defaultChecked={device_token_unique} /></span>
                                </Col>
                                <Col className="switch">
                                    <span className="lable switch"><CustomInput type="switch" id="api_token_unique" name="api_token_unique" label="网站Token唯一性" onClick={(ev) => this.settingsSwitch(ev)} defaultChecked={api_token_unique} /></span>
                                </Col>
                                <Col className="switch">
                                    <span className="lable switch"><CustomInput type="switch" id="weixin_token_unique" name="weixin_token_unique" label="微信Token唯一性" onClick={(ev) => this.settingsSwitch(ev)} defaultChecked={weixin_token_unique} /></span>
                                </Col>
                            </FormGroup>
                        </CardBody>
                    </Card>
                </CardGroup>

                <CardGroup>
                    <Card>
                        <CardBody>
                            <CardTitle><i className="iconfont icontoken1"></i>Token有效期</CardTitle>
                            <FormGroup>
                                <Col>
                                    {this.rangeOptions('设备Token有效期', 'token_expire_device', token_expire_device)}
                                </Col>
                                <Col>
                                    {this.rangeOptions('微信Token有效期', 'token_expire_weixin', token_expire_weixin)}
                                </Col>
                                <Col>
                                    {this.rangeOptions('网站Token有效期', 'token_expire_api', token_expire_api)}
                                </Col>
                                <Col>
                                    <Button color="success" onClick={(ev) => this.settingsSave(ev, 'token_expire')}>保存</Button>
                                    <Button>重置</Button>
                                </Col>
                            </FormGroup>
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <CardTitle><i className="iconfont iconxianzhixinxi"></i>登陆限制</CardTitle>
                            <Col>
                                <span className="lable">登录失败阈值</span>
                                <Input type="number" defaultValue={login_error_threshold} name="login_error_threshold" className="token-input" onChange={(ev) => this.settingsInput(ev)}></Input>
                                <span className="value">次</span>
                            </Col>
                            <Col>
                                {this.rangeOptions('登录失败锁定', 'login_error_expire', login_error_expire)}
                            </Col>
                            <Col>
                                <Button color="success" onClick={(ev) => this.settingsSave(ev, 'login_error')}>保存</Button>
                                <Button>重置</Button>
                            </Col>
                        </CardBody>
                    </Card>
                </CardGroup>

                <CardGroup>
                    <Card>
                        <CardBody>
                            <CardTitle><i className="iconfont iconyanzhengma"></i>验证码有效期</CardTitle>
                            <Col>
                                <span className="lable">短信验证码有效期</span>
                                <Input type="number" defaultValue={captcha_expire_code} name="captcha_expire_code" className="token-input" onChange={(ev) => this.settingsInput(ev)}></Input>
                                <span className="value">秒</span>
                            </Col>
                            <Col>
                                <span className="lable">图片验证码有效期</span>
                                <Input type="number" defaultValue={captcha_expire_image} name="captcha_expire_image" className="token-input" onChange={(ev) => this.settingsInput(ev)}></Input>
                                <span className="value">秒</span>
                            </Col>
                            <Col>
                                <Button color="success" onClick={(ev) => this.settingsSave(ev, 'captcha_expire')}>保存</Button>
                                <Button>重置</Button>
                            </Col>
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <CardTitle><i className="iconfont iconhuancun"></i>缓存有效期</CardTitle>
                            <Col>
                                <span className="lable">报表数据缓存</span>
                                <Input type="number" defaultValue={cache_expire_report} name="cache_expire_report" className="token-input" onChange={(ev) => this.settingsInput(ev)}></Input>
                                <span className="value">秒</span>
                            </Col>
                            <Col>
                                <Button color="success" onClick={(ev) => this.settingsSave(ev, 'cache_expire')}>保存</Button>
                                <Button>重置</Button>
                            </Col>
                        </CardBody>
                    </Card>
                </CardGroup>

            </AdminLayout>)
    }

}
const mapStateToProps = (state, ownProps) => {
    return {
        settings: state.adminData.settings,
        change: state.adminData.change
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getSettings: () => dispatch(getSettingsAction()),
        changeSettings: (key, value) => dispatch(changeSettingsAction(key, value))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);