
/**
 * 布局组件
 */
import React from 'react';
import Tobbar from '../../components/Topbar'
import ErrorPage from '../../components/ErrorPage'
import LeftNav from '../../components/LeftNav';
import PageInfo from '../../components/PageInfo';
import './AdminLayout.scss'
import {
    Container,
    Col,
    Row
} from 'reactstrap';

class AdminLayout extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showElem: window.sessionStorage.displayCp == 1
        }
    }

    render() {
        const { children, modal, props, className } = this.props;

        return (
            <div className="app">

                <Tobbar modal={modal} props={props} />

                {this.state.showElem ? (
                    <div className="main">
                        <Col xl={1} md={1} className="navbar-vertical-content">
                            <LeftNav props={props} />
                        </Col>
                        <Container fluid={true} className="admin-container">
                            <Row className="flex-xl-nowrap">
                                <Container fluid={true} >
                                    <PageInfo props={props} />
                                    <div className={className}>
                                        {children}
                                    </div>
                                </Container>
                            </Row>                           
                        </Container>
                    </div>
                ) : <ErrorPage title="禁止访问" message="对不起，您的账号没有权限访问改页面，请联系管理员开通权限。" props={props} />}
            </div>
        )
    }
}

export default AdminLayout;