import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { getListAction, readAction, deleteAction } from './actions'
import moment from 'moment'
import Util from '../../utils/Util'
import _ from 'loadsh'
import {
    Container,
    Row,
    Col,
    Card,
    Media,
    Alert,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Button
} from 'reactstrap'
import Topbar from '../../components/Topbar';
// import './Message.scss';

class Message extends React.Component {
    constructor(props) {
        super(props);
        this.selectedMsg = this.selectedMsg.bind(this)
        this.state = {
            showElem: window.sessionStorage.role == 'admin',
            errorShow: false,
            errorMessage: "",
            filter: { page: 1, size: 10 },
            current: {
            },
            remove: {
                id: 0,
                title: null,
                body: null,
                modal: false
            }
        }
    }

    // render后
    componentDidMount() {
        console.log('Component DID MOUNT!')
        window.addEventListener('resize', this.handleResize.bind(this))

        let token = window.sessionStorage.token
        if (typeof token === "undefined") {
            this.props.history.push("/logout")
        } else {
            this.props.history.push("/inbox")
            // this.props.getList(this.state.filter)
        }
    }

    // 在组件接收到新的props或者state时被调用
    shouldComponentUpdate(nextProps, nextState) {
        console.log('Component SHOULD UPDATE!')

        try {
            if (nextProps.list.statusCode === 401 || nextProps.readed.statusCode === 401) {
                this.props.history.push("/logout")
            }
        } catch (e) { }

        try {
            if (nextProps.list.statusCode !== 200) {
                if (nextState == this.state) {
                    this.setState({
                        errorShow: true,
                        errorMessage: nextProps.list.message,
                        currentId: ""
                    })
                }
            }
        } catch (e) { }

        try {
            if (nextProps.readed.statusCode !== 200) {
                if (nextState == this.state) {
                    this.setState({
                        errorShow: true,
                        errorMessage: nextProps.readed.message
                    })
                }
            }
        } catch (e) { }

        try {
            if (nextProps.deleted.statusCode !== 200) {
                if (nextState == this.state) {
                    this.setState({
                        errorShow: true,
                        errorMessage: nextProps.deleted.message
                    })
                }
            }
        } catch (e) { }

        try {
            if (nextProps.deleted !== this.props.deleted) {
                this.props.getList(this.state.filter)
            }
        } catch (e) { }

        try {
            if (!nextState.current.Id) {
                this.selectedMsg(nextProps.list.data.rows[0])
            } else if (this.props.list != nextProps.list) {
                this.selectedMsg(nextProps.list.data.rows[0])
            }
        } catch (e) { }

        return true;
    } 
    renderAlert(alert, id) {
        return (
            <Alert color={alert.type} isOpen={alert.modal} fade={false} key={id} toggle={e => this.onDismiss(e, id)}>
                {alert.message}
            </Alert>
        )
    }
    onDismiss(e, id) {
        let alerts = this.state.alerts
        alerts[id].modal = false

        this.setState({
            alerts: alerts
        })
    }


    // 在组件完成更新后立即调用
    componentDidUpdate(prevProps, prevState) {
        console.log('Component DID UPDATE!')
    }

    // 在组件从 DOM 中移除之前立刻被调用
    componentWillUnmount() {
        console.log('Component WILL UNMOUNT!')
    }

    handleResize = e => {
        let filter = this.state.filter
        filter.size = ((e.target.innerHeight - 60) / 70).toFixed()
        this.setState({
            filter: filter
        })
    }

    selectedMsg(ev, id) {
        id = id ? id : ev
        var msg = {}

        if (typeof id === "object") {
            msg = id
            id = msg.id
        } else {
            let rows = this.props.list.data.rows
            msg = _.find(rows, (o) => { return o.id === id })
        }

        let current = this.state.current
        current.Id = id
        current.Fn = msg.fn
        current.Receiver = msg.receiver
        current.Sender = msg.sender
        current.ReceiveAt = Util.s2ss(msg.receive_at)
        current.Content = msg.content
        current.Sim = msg.sim
        current.Apn = msg.apn
        current.Location = msg.location
        current.Mark = msg.mark
        current.Static = msg.static
        current.Avatar = msg.avatar
        current.Name = msg.name
        this.setState({
            current: current
        })
        if (msg.readed === 'N' && msg.owner == 1) {
            this.props.read(id)
        }
    }

    deleteMsg(id) {
        this.props.delete(id)
        let remove = this.state.remove
        remove.modal = !remove.modal
        this.setState({
            remove: remove
        })
    }

    toggle(ev) {
        this.setState({
            remove: {
                id: this.state.current.Id,
                title: "请确认",
                body: this.removeConfirm(),
                confirm: "删除",
                modal: !this.state.remove.modal
            }
        })
    }

    removeConfirm() {
        if (!this.state.current.Id)
            return (<div></div>)
        return (
            <div>
                <Card className={`message-header`}>

                    <Col md={12}>
                        <Col className="avatar fl" >
                            <img src={`${this.state.current.Static}${this.state.current.Avatar}`} alt={this.state.current.Fn} />
                        </Col>
                        <Col className="fl base-info">
                            <Row>
                                <dt>接收:</dt>
                                <dd className={`sim sim-${this.state.current.Sim}`}>{this.state.current.Name}<span>&lt;{this.state.current.Receiver}&gt;</span></dd>
                            </Row>
                            <Row>
                                <dt>发送:</dt>
                                <dd>
                                    {this.state.current.Fn}
                                    <span className={this.state.current.Fn === this.state.current.Sender ? "hide" : ""}>&lt;{this.state.current.Sender}&gt;</span>
                                </dd>
                                <a href={`https://www.baidu.com/s?wd=${this.state.current.Sender}`} rel="noopener noreferrer" target="_blank" className="btn-search">
                                    <i className="iconfont">&#xe615;</i>
                                </a>
                            </Row>
                            <Row>
                                <dt>时间:</dt>
                                <dd>{this.state.current.ReceiveAt}</dd>
                            </Row>
                            <Row >
                                <dt>标签:</dt>
                                <dd className="apn-tag cell-mark-detail">{this.state.current.Apn}</dd>
                                <div className="cell-mark">
                                    <dd className="bg-warning cell-mark-detail">{this.state.current.Mark}</dd>
                                    <dd className="bg-warning cell-mark-detail">{this.state.current.Location}</dd>
                                </div>
                            </Row>
                        </Col>
                    </Col>
                </Card>
                <Col className="message-body">
                    <div>{this.state.current.Content}</div>
                </Col>
            </div>
        )
    }

    pager(ev, page) {
        if (page > 0) {
            
            let filter = this.state.filter
            filter.page = page

            let current = this.state.current
            current.Id = ""
            current.Fn = ""
            current.Receiver = ""
            current.Sender = ""
            current.ReceiveAt = ""
            current.Content = ""
            current.Sim = ""
            
            this.setState({ current: current, filter: filter })
            this.props.getList(filter)
        }
    }

    renderRow(props, key) {
        try {
            var receive_at = moment(props.receive_at).format('M/D HH:mm')
            if (moment(props.receive_at).format('Y') !== moment().format('Y')) {
                receive_at = moment(props.receive_at).format('YY/M/D HH:mm')
            }

            if (this.props.readed) {
                if (this.props.readed.data.id === props.id && this.props.readed.statusCode === 200) {
                    _.find(this.props.list.data.rows, (o) => {
                        if (o.id == props.id) {
                            props.readed = "Y"
                        }
                    })
                }
            }

            var className = ""
            if (this.state.current.Id === props.id) {
                className = "active"
            }
        } catch (e) { console.log(e) }

        return (
            <Media onClick={(ev) => this.selectedMsg(ev, props.id)} onDoubleClick={(ev => this.toggle(ev))} className={className} key={key}>
                <Media left className="avatar">
                    <Media object src={`${props.static}${props.avatar}`} alt={props.fn} />
                </Media>
                <Media body>
                    <Media heading>
                        <Row className="dt">
                            <span className={`sim sim-${props.sim}`}>{props.fn.substring(0, 12)}</span>
                            <span className={props.readed === 'N' ? "dot" : ""}></span>
                            <div className="fr" >
                                <span >{receive_at}</span>
                                {/* <i className="iconfont remove" onClick={(ev => this.deleteMsg(props.id))}>&#xe62d;</i> */}
                            </div>
                        </Row>
                    </Media>
                    <Col className="p0">
                        <Row style={{ wordBreak: "break-all" }}>
                            <dd>{props.content}</dd>
                        </Row>
                    </Col>
                </Media>
            </Media>
        )
    }

    renderPager(pagination, currentPager) {
        let nextDisabled = ""
        if (pagination.current === pagination.page) {
            nextDisabled = "disabled"
        }

        let prevDisabled = ""
        if (pagination.current === 1) {
            prevDisabled = "disabled"
        }

        return (
            <ul className="pagination justify-content-center">
                <li className={`page-item ${prevDisabled}`}>
                    <button className="page-link" onClick={(ev) => this.pager(ev, 1)}>First</button>
                </li>

                <li className={`page-item ${prevDisabled}`}>
                    <button className="page-link" onClick={(ev) => this.pager(ev, this.state.filter.page - 1)}>Prev</button>
                </li>

                <li className="page-item active">
                    <span className="page-link">
                        {currentPager}
                        <span className="sr-only">(current)</span>
                    </span>
                </li>

                <li className={`page-item ${nextDisabled}`}>
                    <button className={`page-link ${nextDisabled}`} onClick={(ev) => this.pager(ev, this.state.filter.page + 1)}>Next</button>
                </li>

                <li className={`page-item ${nextDisabled}`}>
                    <button className={`page-link`} onClick={(ev) => this.pager(ev, pagination.page)}>Last</button>
                </li>
            </ul>
        )
    }

    renderCurrentPager() {
        return (
            <div className="d-loader-box">
                <div className="loader-15"></div>
            </div>
        )
    }

    render() {
        try {
            var listBody, pager, modal, msgDetail

            if (this.props.list && Object.keys(this.props.list.data).length) {
                var currentPager = `${this.props.list.data.pagination.current} of ${this.props.list.data.pagination.page}`
                if (this.props.list.data.pagination.current !== this.state.filter.page) {
                    modal = true
                    currentPager = this.renderCurrentPager()
                }

                if (Object.keys(this.props.list.data.rows).length > 0) {
                    listBody = Object.keys(this.props.list.data.rows).map(key => this.renderRow(this.props.list.data.rows[key], key))
                    pager = this.renderPager(this.props.list.data.pagination, currentPager)
                    msgDetail = this.removeConfirm()
                }
            }
        } catch (e) { }

        return (<div>
            <Helmet>
                <title>{`信息中心 - ${process.env.REACT_APP_NAME}`}</title>
            </Helmet>
            <Topbar modal={modal} props={this.props} />
            <div>
                <Modal isOpen={this.state.remove.modal} toggle={(ev) => this.toggle(ev)} className="message-modal">
                    <ModalHeader toggle={(ev) => this.toggle(ev)}>{this.state.remove.title}</ModalHeader>
                    <ModalBody>{this.state.remove.body}</ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={(ev => this.deleteMsg(this.state.remove.id))}>{this.state.remove.confirm}</Button>{' '}
                        <Button color="secondary" onClick={(ev) => this.toggle(ev)}>取消</Button>
                    </ModalFooter>
                </Modal>
            </div>
            <Container className="message">
                <Alert color="danger" isOpen={this.state.errorShow} >
                    {this.state.errorMessage}
                </Alert>
                <Row>
                    <Col md={4} className="message-left">
                        {listBody}
                    </Col>
                    <Col md={8} className="detail">
                        {msgDetail}
                        <Col md={12} >
                            <Col md={9} className="pager-fixed">
                                <nav aria-label="Page navigation">
                                    {pager}
                                </nav>
                            </Col>
                        </Col>
                    </Col>
                </Row>
            </Container>
        </div>)
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        list: state.homeData.list,
        readed: state.homeData.readed,
        deleted: state.homeData.deleted
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getList: (filter) => dispatch(getListAction(filter)),
        read: (id) => dispatch(readAction(id)),
        delete: (id) => dispatch(deleteAction(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Message);