export const LOGS = 'ADMIN_LOGS'
export const RLOGS = 'RLOGS'
export const ONLINE = 'ONLINE'
export const OFFLINE = 'OFFLINE'
export const LOCKED = 'LOCKED'
export const UNLOCK = 'UNLOCK'
export const SETTINGS = 'SETTINGS'
export const CHANGESETTINGS = 'CHANGESETTINGS'
export const DELDENY = 'DELDENY'
export const USERS = 'USERS'
export const CREATE_DENY = 'CREATE_DENY'
export const DENY_LIST = 'DENY_LIST'
export const LOGS_CONSUMER = 'LOGS_CONSUMER'
export const DO_LOGS_CONSUMER = 'DO_LOGS_CONSUMER'
export const DASHBOARD = 'DASHBOARD'
export const SYSINFO = 'SYSINFO'
export const SETTINGS_CACHE = 'SETTINGS_CACHE'
export const MAKE_WX_URI = 'MAKE_WX_URI'