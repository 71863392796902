/* eslint no-shadow:0 */
import React from 'react';
import { connect } from 'react-redux';
import { deleteUserInfoAction } from './actions';

class Logout extends React.Component {
    constructor(props) {
        super(props);
        // this._logout = this._logout.bind(this);
    }

    // _logout() {
       
    // }

    componentDidMount() {
        if (window.sessionStorage.token !== undefined) {
            this.props._logout();
        }
        window.sessionStorage.clear()
        this.props.history.push("/login");
    }

    render() {
        return (<div></div>);
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        loginData: state.loginData,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        _logout: () => dispatch(deleteUserInfoAction()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Logout);
