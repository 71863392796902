import React from 'react';
import { connect } from 'react-redux';
import { getDenyAction, delDenyAction, saveDenyAction } from './actions';

import {
    Table,
    Col,
    Label,
    Input,
    FormGroup,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Alert,
    Button
} from 'reactstrap';

import moment from 'moment';
import { JsonView } from 'json-view-for-react'
import AdminLayout from '../../containers/Admin/AdminLayout'
import { LANGUAGE } from '../../config/Language';

class Deny extends React.Component {

    constructor(props) {
        super(props)
        let type = this.props.match.path.split('/')[3]
        this.state = {
            showElem: window.sessionStorage.role == 'admin',
            alerts: [],
            filter: { type: type, page: 1 },
            errorShow: false,
            errorMessage: "",
            modal: false,
            createData: {
                type: type,
                reason: '恶意攻击'
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        try {
            for (let i in nextProps) {
                if (nextProps[i] && nextProps[i].statusCode) {
                    if (nextProps[i].statusCode == 401) {
                        this.props.history.push("/logout")
                    } else if (nextProps[i].statusCode !== 200 && nextState == this.state) {
                        var alerts = this.state.alerts
                        alerts.push({
                            modal: true,
                            message: nextProps[i].message,
                            type: "danger"
                        })
                        this.setState({
                            alerts: alerts
                        })
                    }

                }
            }
        } catch (e) { }

        try {
            if (nextProps.del !== this.props.del || this.props.create != nextProps.create) {
                this.props.getDeny(this.state.filter)
            }
        } catch (e) { }

        return true;
    }
    renderAlert(alert, id) {
        return (
            <Alert color={alert.type} isOpen={alert.modal} fade={false} key={id} toggle={e => this.onDismiss(e, id)}>
                {alert.message}
            </Alert>
        )
    }
    onDismiss(e, id) {
        let alerts = this.state.alerts
        alerts[id].modal = false

        this.setState({
            alerts: alerts
        })
    }


    componentDidMount() {
        this.props.getDeny(this.state.filter)
    }

    handleCreate(e) {
        let createData = this.state.createData
        createData[e.target.name] = e.target.value
        this.setState({ createData })
    }

    saveDeny() {
        this.props.saveDeny(this.state.createData)
    }

    delete(ev, id) {
        this.props.delDeny(id)
    }

    handleFilter(e) {
        let filter = this.state.filter
        filter[e.target.name] = e.target.value

        this.setState({
            filter: filter
        })
    }

    search(e) {
        let filter = this.state.filter
        filter.page = 1
        this.setState({ filter: filter })
        this.props.getDeny(filter)
    }

    pager(ev, page) {
        if (page > 0) {
            let filter = this.state.filter
            filter.page = page
            this.setState({ filter: filter })
            this.props.getDeny(filter)
        }
    }

    renderCurrentPager() {
        return (
            <div className="d-loader-box">
                <div className="loader-15"></div>
            </div>
        )
    }

    renderPagination(pagination, currentPager) {
        let nextDisabled = ""
        if (pagination.current === pagination.page) {
            nextDisabled = "disabled"
        }

        let prevDisabled = ""
        if (pagination.current === 1) {
            prevDisabled = "disabled"
        }

        return (
            <ul className="pagination justify-content-center">
                <li className={`page-item ${prevDisabled}`}>
                    <button className="page-link" onClick={(ev) => this.pager(ev, 1)}>First</button>
                </li>

                <li className={`page-item ${prevDisabled}`}>
                    <button className="page-link" onClick={(ev) => this.pager(ev, this.state.currentPage - 1)}>Prev</button>
                </li>

                <li className="page-item active">
                    <span className="page-link">
                        {currentPager}
                        <span className="sr-only">(current)</span>
                    </span>
                </li>

                <li className={`page-item ${nextDisabled}`}>
                    <button className={`page-link ${nextDisabled}`} onClick={(ev) => this.pager(ev, this.state.currentPage + 1)}>Next</button>
                </li>

                <li className={`page-item ${nextDisabled}`}>
                    <button className={`page-link`} onClick={(ev) => this.pager(ev, pagination.page)}>Last</button>
                </li>
            </ul>
        )
    }

    renderOption(props) {
        return (
            <option key={props.type} value={props.type}>
                {LANGUAGE[props.type]}
            </option>
        )
    }

    renderTable(data, key) {
        return (
            <tr key={key}>
                <td>{Number(key) + 1}</td>
                <td> {LANGUAGE[data.type]}</td>
                <td>{data.start}</td>
                <td>{data.end}</td>
                <td>{data.count}个</td>
                <td>{moment(data.created_at).format('YYYY/MM/DD HH:mm:ss')}</td>
                <td>
                    <Button color="link" className="kill" onClick={(ev) => this.delete(ev, data.id)} title="删除">
                        <i className="iconfont">&#xe621;</i>
                    </Button>
                    <Button color="link" onClick={ev => this.toggle(ev, data)} title="详情">
                        <i className="iconfont iconjson"></i>
                    </Button>
                </td>
            </tr >
        )
    }

    renderDenyModalBody() {
        return (
            <div>
                <ModalBody>
                    <FormGroup row>
                        <Label for="exampleZip" md={2}>类型</Label>
                        <Col sm={3}>
                            {LANGUAGE[this.state.createData.type]}
                            {/* <Input bsSize="sm" type="select" name="type" onChange={(ev) => this.handleCreate(ev)}>
                                    {type}
                                </Input> */}
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="exampleZip" md={2}>起始值</Label>
                        <Col sm={5}>
                            <Input bsSize="sm" type="text" name="start" onChange={(ev) => this.handleCreate(ev)} ></Input>
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="exampleZip" md={2}>终止值</Label>
                        <Col sm={5}>
                            <Input bsSize="sm" type="text" name="end" onChange={(ev) => this.handleCreate(ev)} ></Input>
                        </Col>
                    </FormGroup>

                    <FormGroup row>
                        <Label for="exampleZip" md={2}>原因</Label>
                        <Col sm={5}>
                            <Input bsSize="sm" type="text" name="reason" onChange={(ev) => this.handleCreate(ev)} defaultValue={this.state.createData.reason} ></Input>
                        </Col>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={(ev) => this.saveDeny(ev)}>保存</Button>{' '}
                    <Button color="secondary" onClick={(ev) => this.toggle(ev)}>取消</Button>
                </ModalFooter>
            </div>
        )
    }

    toggle(ev, flag) {

        if (typeof flag == 'object') {
            this.setState({
                modal: !this.state.modal,
                modalTitle: "详细信息",
                modalBody: <ModalBody> <JsonView obj={flag} showLineNumbers /></ModalBody>
            })
        } else {
            let title = '新建黑名单'

            if (flag === 2) {
                title = `修改`
            }

            this.setState({
                modal: !this.state.modal,
                modalTitle: title,
                modalBody: this.renderDenyModalBody(),
                flag: flag
            })
        }
    }

    render() {
        try {
            var list, type, pagination
            var closeBtn = <button className="close" onClick={(ev) => this.toggle(ev)}>&times;</button>
            var currentPager = `${this.props.deny.data.pagination.current} of ${this.props.deny.data.pagination.page}`

            if (this.props.deny.data.pagination.current !== this.state.filter.page) {
                currentPager = this.renderCurrentPager()
            }

            if (Object.keys(this.props.deny.data.rows).length) {
                pagination = this.renderPagination(this.props.deny.data.pagination, currentPager)
                list = Object.keys(this.props.deny.data.rows).map(key => this.renderTable(this.props.deny.data.rows[key], key))
                type = Object.keys(this.props.deny.data.filter.type).map(key => this.renderOption(this.props.deny.data.filter.type[key], key))
            }

        } catch (e) { }


        try {
            var alerts = Object.keys(this.state.alerts).map(key => this.renderAlert(this.state.alerts[key], key))
        } catch (e) { }

        return (
            <AdminLayout modal={this.state.modal} props={this.props} className="deny">

                <FormGroup row className="search-box">
                    <Label for="examplePassword">行数</Label>
                    <Input bsSize="sm" type="select" name="size" onChange={(ev) => this.handleFilter(ev)} defaultValue={this.state.filter.size}>
                        <option>10</option>
                        <option>15</option>
                        <option>20</option>
                        <option>25</option>
                        <option>30</option>
                    </Input>
                    {/* <Label for="examplePassword">类别</Label>
                    <Input bsSize="sm" type="select" name="type" onChange={(ev) => this.handleFilter(ev)} defaultValue={this.state.filter.type}>
                        {type}
                    </Input> */}
                    <Label for="examplePassword">关键词</Label>
                    <Input bsSize="sm" type="text" name="ext" onChange={(ev) => this.handleFilter(ev)} />
                    <button className="btn btn-secondary" onClick={(ev) => this.search(ev)}>搜索</button>
                    <button className="btn btn-primary" onClick={(ev) => this.toggle(ev, 1)}>添加</button>
                </FormGroup>

                <Table >
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>类型</th>
                            <th>起始值</th>
                            <th>终止值</th>
                            <th>有效数</th>
                            <th>创建时间</th>
                            <th>操作</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list}
                    </tbody>
                </Table>
                <Col style={{ textAlign: "center" }}>
                    {pagination}
                </Col>


                <Modal isOpen={this.state.modal} toggle={(ev) => this.toggle(ev)} style={{ maxWidth: 650 }}>
                    <ModalHeader toggle={(ev) => this.toggle(ev)} close={closeBtn}>{this.state.modalTitle}</ModalHeader>
                    {this.state.modalBody}
                </Modal>

            </AdminLayout>
        )
    }

}
const mapStateToProps = (state, ownProps) => {
    return {
        deny: state.adminData.deny,
        del: state.adminData.del,
        create: state.adminData.create
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getDeny: (filter) => dispatch(getDenyAction(filter)),
        delDeny: (id) => dispatch(delDenyAction(id)),
        saveDeny: (data) => dispatch(saveDenyAction(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Deny);