
import React from 'react';
import { Helmet } from 'react-helmet';
import {
    Col
} from 'reactstrap';

import './PageInfo.scss'
const menu = require('../config/adminMenu.json')

class PageInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showElem: window.sessionStorage.role == 'admin',
            title: false,
            description: null,
            breadcrumb: null
        }
    }

    componentDidMount() {
        try {
            var title, description
            var breadcrumb = [<li className="breadcrumb-item active" aria-current="page" key="first">后台</li>]

            for (var lable in menu) {
                if (menu[lable].children) {
                    for (let k in menu[lable].children) {
                        if (menu[lable].children[k].path == this.props.props.match.path) {
                            title = k
                            description = menu[lable].children[k].description
                            breadcrumb.push(<li className="breadcrumb-item active" aria-current="page" key="second">{lable}</li>)
                            breadcrumb.push(<li className="breadcrumb-item active" aria-current="page" key="last">{title}</li>)
                        }
                    }
                } else {
                    if (menu[lable].path == this.props.props.match.path) {
                        title = lable
                        description = menu[lable].description
                        breadcrumb.push(<li className="breadcrumb-item active" aria-current="page" key="last">{lable}</li>)
                    }
                }
            }
            this.setState({
                title: title,
                description: description,
                breadcrumb: breadcrumb
            })
        } catch (e) { }
    }

    render() {
        return (
            <div className="page-info">
                <Helmet>
                    <title>{`${this.state.title} - ${process.env.REACT_APP_NAME}`}</title>
                </Helmet>

                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        {this.state.breadcrumb}
                    </ol>
                </nav>

                <Col className="page-header">
                    <h2>{this.state.title}</h2>
                    <p><i className="iconfont iconAlertprocessing text-warning"></i>{this.state.description}</p>
                </Col>
            </div>
        )
    }
}

export default PageInfo