import React from 'react';
import { connect } from 'react-redux';
// javascript plugin used to create scrollbars on windows
import { logsConsumerAction, doConsumerAction } from './actions';
import {
    Col,
    Table,
    Button,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    FormGroup,
    Label, Alert,
    Input
} from 'reactstrap';
import AdminLayout from '../../containers/Admin/AdminLayout'
import { JsonView } from 'json-view-for-react'
import Util from '../../utils/Util'

class LogsConsumer extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showElem: window.sessionStorage.role == 'admin',
            alerts: [],
            filter: { page: 1, size: 10 },
            modal: false,
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        try {
            for (let i in nextProps) {
                if (nextProps[i] && nextProps[i].statusCode) {
                    if (nextProps[i].statusCode == 401) {
                        this.props.history.push("/logout")
                    } else if (nextProps[i].statusCode !== 200 && nextState == this.state) {
                        var alerts = this.state.alerts
                        alerts.push({
                            modal: true,
                            message: nextProps[i].message,
                            type: "danger"
                        })
                        this.setState({
                            alerts: alerts
                        })
                    }
                }
            }
        } catch (e) { }

        try {
            if (nextProps.doLogsConsumer.statusCode == 200 && nextState == this.state && this.props.logs == nextProps.logs) {
                this.setState({
                    modal: true,
                    modalTitle: '消费完成',
                    modalBody: `共计消费${nextProps.doLogsConsumer.data.rows}条日志`
                })
            }
        } catch (e) { }

        try {
            if (nextProps.doLogsConsumer !== this.props.doLogsConsumer) {
                this.props.logsConsumer(this.state.filter)
            }
        } catch (e) { }

        return true;
    } 
    renderAlert(alert, id) {
        return (
            <Alert color={alert.type} isOpen={alert.modal} fade={false} key={id} toggle={e => this.onDismiss(e, id)}>
                {alert.message}
            </Alert>
        )
    }
    onDismiss(e, id) {
        let alerts = this.state.alerts
        alerts[id].modal = false

        this.setState({
            alerts: alerts
        })
    }



    componentDidMount() {
        this.props.logsConsumer(this.state.filter)
    }


    renderLogs(data, key) {
        try {
            data = JSON.parse(data)
            if (!data.user) {
                data.user = {
                    name: "游客",
                    system: "未知"
                }
            }
        } catch (e) { }
        return (
            <tr key={key}>
                <td>{Number(key) + 1}</td>
                <td className="log-detal">{data.remote}</td>
                <td className="log-detal">{data.method}</td>
                <td className="log-detal">{data.host}</td>
                <td className="log-detal">{data.path}</td>
                <td className="log-detal">{data.user.name}</td>
                <td className="log-detal">{data.brand_model}</td>
                <td className="log-detal">{data.system}</td>
                <td className="log-detal">{data.app_version}</td>
                <td className="log-detal">{Util.s2ss(data.created_at / 1000)}</td>
                <td><Button color="link" onClick={ev => this.toggle(ev, data)}><i className="iconfont iconjson"></i></Button></td>
            </tr >
        )
    }

    toggle(ev, data) {

        try {
            var body
            data = JSON.parse(data)
        } catch (e) {
            body = <JsonView obj={data} showLineNumbers />
        }

        this.setState({
            modal: !this.state.modal,
            modalTitle: "详细信息",
            modalBody: body
        })
    }

    handleFilter(e) {
        let filter = this.state.filter
        filter[e.target.name] = e.target.value

        this.setState({
            filter: filter
        })
        this.props.logsConsumer(filter)
    }

    search(e) {
        let filter = this.state.filter
        filter.page = 1
        this.setState({ filter: filter })
        this.props.logsConsumer(this.state.filter)
    }

    renderCurrentPager() {
        return (
            <div className="d-loader-box">
                <div className="loader-15"></div>
            </div>
        )
    }

    renderPagination(pagination, currentPager) {
        let nextDisabled = ""
        if (pagination.current === pagination.page) {
            nextDisabled = "disabled"
        }

        let prevDisabled = ""
        if (pagination.current === 1) {
            prevDisabled = "disabled"
        }

        return (
            <ul className="pagination justify-content-center">
                <li className={`page-item ${prevDisabled}`}>
                    <button className="page-link" onClick={(ev) => this.pager(ev, 1)}>First</button>
                </li>

                <li className={`page-item ${prevDisabled}`}>
                    <button className="page-link" onClick={(ev) => this.pager(ev, this.state.filter.page - 1)}>Prev</button>
                </li>

                <li className="page-item active">
                    <span className="page-link">
                        {currentPager}
                        <span className="sr-only">(current)</span>
                    </span>
                </li>

                <li className={`page-item ${nextDisabled}`}>
                    <button className={`page-link ${nextDisabled}`} onClick={(ev) => this.pager(ev, this.state.filter.page + 1)}>Next</button>
                </li>

                <li className={`page-item ${nextDisabled}`}>
                    <button className={`page-link`} onClick={(ev) => this.pager(ev, pagination.page)}>Last</button>
                </li>
            </ul>
        )
    }

    pager(ev, page) {
        if (page > 0) {
            let filter = this.state.filter
            filter.page = page
            this.setState({ filter: filter })
            this.props.logsConsumer(this.state.filter)
        }
    }

    doConsumer() {
        this.props.doConsumer()
    }

    render() {
        try {
            var logs, pagination, currentPager

            if (this.props.logs && Object.keys(this.props.logs.data.rows).length) {
                currentPager = `${this.props.logs.data.pagination.current} of ${this.props.logs.data.pagination.page}`
                if (this.props.logs.data.pagination.current !== this.state.filter.page) {
                    currentPager = this.renderCurrentPager()
                }

                pagination = this.renderPagination(this.props.logs.data.pagination, currentPager)
                logs = Object.keys(this.props.logs.data.rows).map(key => this.renderLogs(this.props.logs.data.rows[key], key))
            }

        } catch (e) { }

        
        try {
            var alerts = Object.keys(this.state.alerts).map(key => this.renderAlert(this.state.alerts[key], key))
        } catch (e) { }

        return (
            <AdminLayout modal={this.state.modal} props={this.props} className="logs-consumer">

                <Modal isOpen={this.state.modal} toggle={(ev) => this.toggle(ev)}>
                    <ModalHeader toggle={(ev) => this.toggle(ev)} className="text-danger">{this.state.modalTitle}</ModalHeader>
                    <ModalBody id="modal-body">{this.state.modalBody}</ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={(ev) => this.toggle(ev)}>好的</Button>
                    </ModalFooter>
                </Modal>
                <FormGroup row className="search-box">
                    <Label for="examplePassword">行数</Label>
                    <Input bsSize="sm" type="select" name="size" onChange={(ev) => this.handleFilter(ev)} defaultValue={this.state.filter.size}>
                        <option>10</option>
                        <option>15</option>
                        <option>20</option>
                        <option>25</option>
                        <option>30</option>
                    </Input>
                    <Button color="danger" onClick={(ev) => this.doConsumer(ev)}>强制消费</Button>
                    <Button color="success" onClick={(ev) => this.search(ev)}>刷新</Button>
                </FormGroup>

                <Table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>客户IP</th>
                            <th>METHOD</th>
                            <th>HOST</th>
                            <th>PATH</th>
                            <th>用户名</th>
                            <th>终端</th>
                            <th>系统</th>
                            <th>应用</th>
                            <th>时间</th>
                            <th>操作</th>
                        </tr>
                    </thead>
                    <tbody>
                        {logs}
                    </tbody>
                </Table>
                <Col style={{ textAlign: "center" }}>
                    {pagination}
                </Col>

            </AdminLayout>
        )

    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        logs: state.adminData.logsConsumer,
        doLogsConsumer: state.adminData.doLogsConsumer,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        logsConsumer: (filter) => dispatch(logsConsumerAction(filter)),
        doConsumer: (filter) => dispatch(doConsumerAction(filter))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogsConsumer);