
import React from 'react';
import {
    Container,
    Col,
    Row
} from 'reactstrap';
import { Link } from "react-router-dom";
import './ErrorPage.scss';

class ErrorPage extends React.Component {
    constructor(props) {
        super(props);
    }

    go(e) {
        e.preventDefault()
        this.props.history.go(-1)
    }

    renderButton() {
        return (
            <div className="text-center">
                <Link to="/" onClick={(ev) => this.go(ev)} className="btn btn-secondary">
                    <i className="iconfont">&#xe6a9;</i>
                    后退
				</Link>{' '}
                <Link to="/" className="btn btn-primary">
                    <i className="iconfont">&#xe642;</i>
                    首页
				</Link>
            </div>)
    }
    render() {
        try {
            var button = ""
            if (this.props.history) {
                button = this.renderButton()
            }
        } catch (e) { }

        return (
            <Container className="error-page">
                <Col md={12}>
                    <div className="error-container">
                        <div className="well">
                            <h1 className="grey lighter smaller" style={{ color: "#f00" }}>
                                {this.props.title || "404 Not Found"}
                            </h1>
                            <hr />
                            <h3 className="lighter smaller">{this.props.message || "页面被狗狗叼走啦～～～"}</h3>
                            <hr />
                            <div className="space"></div>
                            {button}
                        </div>
                    </div>
                </Col>
            </Container>
        );
    }
}

export default ErrorPage