import MyFetch from '../../utils/MyFetch';
import {
    MESSAGE,
    READED
} from './actionTypes';

export const getMessageAction = (mid, token) => {
    return (dispatch) => {
        const path = `wx/message?mid=${mid}&token=${token}`;
        MyFetch.get(path).then((data) => {
            dispatch(MessageAction({
                message: data
            }))
        })
    }
}

export const readedAction = (mid, token) => {
    return (dispatch) => {
        const path = `wx/message?mid=${mid}&token=${token}`;
        MyFetch.put(path, { readed: 'Y' }).then((data) => {
            dispatch(ReadedAction({
                readed: data
            }))
        })
    }
}

export const loginAction = (mid, token, code) => {
    return (dispatch) => {
        const path = `wx/message`;
        MyFetch.post(path, { code: code, mid: mid, token: token }).then((data) => {
            dispatch(MessageAction({
                login: data
            }))
        })
    }
}

export const MessageAction = (rows) => ({
    type: MESSAGE,
    data: rows
})

export const ReadedAction = (rows) => ({
    type: READED,
    data: rows
})
