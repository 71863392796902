import MyFetch from '../../../utils/MyFetch';
import {
    REALTIME,
    ARCHIVE
} from './actionTypes';

// =====用户管理=====
// 实时数据
export const getRealtimeAction = () => {
    return (dispatch) => {
        const path = `reports/message/realtime`;
        MyFetch.get(path).then((data) => {
            dispatch(setRealtimeAction({
                realtime: data
            }))
        })
    }
}

export const setRealtimeAction = (rows) => ({
    type: REALTIME,
    data: rows
})


export const getArchiveAction = () => {
    return (dispatch) => {
        const path = `reports/message/archive`;
        MyFetch.get(path).then((data) => {
            dispatch(setArchiveAction({
                archive: data
            }))
        })
    }
}

export const setArchiveAction = (rows) => ({
    type: ARCHIVE,
    data: rows
})