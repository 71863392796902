import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { createHashHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/ui-static-all.scss';

import { Message } from './views/Message';
import * as Inbox from './views/Inbox';
import * as User from './views/User';
import * as Admin from './views/Admin';
import * as Contact from './views/Contact';
import * as Weixin from './views/Weixin'
import * as SSO from './views/SSO';
import * as Reports from './views/Admin/Reports'
import * as Tools from './views/Admin/Tools'
import * as Page from './views/Page'
import ErrorPage from './components/ErrorPage'

const history = createHashHistory();

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <Route path="/" exact component={(props) => <Message {...props} />} key={0} />
        <Route path="/inbox" exact component={Inbox.Inbox} key={0} />
        <Route path="/register" exact component={SSO.Register} key={Math.random() * 999} />
        <Route path="/password" exact component={SSO.Password} key={Math.random() * 999} />
        <Route path="/login" exact component={SSO.Login} key={Math.random() * 999} />
        <Route path="/logout" exact component={SSO.Logout} key={Math.random() * 999} />
        <Route path="/user" exact component={User.Center} key={Math.random() * 999} />
        <Route path="/contact" exact component={Contact.Contact} key={Math.random() * 999} />
        <Route path="/wx/message/:mid/:token" exact component={Weixin.Message} key={Math.random() * 999} />

        <Route path="/admin" exact component={Admin.Dashboard} key={Math.random() * 999} />
        <Route path="/admin/reports/dataview" exact component={Reports.DataView} key={Math.random() * 999} />
        <Route path="/admin/reports/message" exact component={Reports.Message} key={Math.random() * 999} />
        <Route path="/admin/reports/api/realtime" exact component={Reports.ApiRealtime} key={Math.random() * 999} />
        <Route path="/admin/reports/api/monthly" exact component={Reports.ApiMonthly} key={Math.random() * 999} />
        <Route path="/admin/reports/api/daily" exact component={Reports.ApiDaily} key={Math.random() * 999} />
        <Route path="/admin/settings" exact component={Admin.Settings} key={Math.random() * 999} />
        <Route path="/admin/users/" exact component={Admin.Users} key={Math.random() * 999} />
        <Route path="/admin/users/online" exact component={Admin.Online} key={Math.random() * 999} />
        <Route path="/admin/users/locked" exact component={Admin.Unlock} key={Math.random() * 999} />
        <Route path="/admin/deny/cell" exact component={Admin.Deny} key={Math.random() * 999} />
        <Route path="/admin/deny/ip" exact component={Admin.Deny} key={Math.random() * 999} />
        <Route path="/admin/logs/system" exact component={Admin.SystemLogs} key={Math.random() * 999} />
        <Route path="/admin/logs/request" exact component={Admin.RequestLogs} key={Math.random() * 999} />
        <Route path="/admin/tools/wxlink" exact component={Tools.WxURL} key={Math.random() * 999} />
        <Route path="/admin/tools/sendsms" exact component={Tools.SendSMS} key={Math.random() * 999} />
        <Route path="/admin/logs/consumer" exact component={Admin.LogsConsumer} key={Math.random() * 999} />
        <Route path="/page/privacy.html" exact component={Page.Page} key={Math.random() * 999} />

        <Route component={ErrorPage} title="404 Not Found" message=""></Route>
      </Switch>
    </Router>
  </Provider >,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// serviceWorker.register();
