import React from 'react';
import { connect } from 'react-redux';
import { getOnlineAction, killAction } from './actions';
import {
    Table,
    Col,
    Label,
    Input,
    FormGroup, Alert,
    Button, Modal, ModalBody, ModalHeader, ModalFooter
} from 'reactstrap';
import moment from 'moment'
import { LANGUAGE } from '../../config/Language';
import Util from '../../utils/Util';
import AdminLayout from '../../containers/Admin/AdminLayout'
import { JsonView } from 'json-view-for-react'


class Online extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showElem: window.sessionStorage.role == 'admin',
            alerts: [],
            filter: { cursor: 0, page: 1 },
            modal: false,
            errorShow: false,
            errorMessage: ""
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        try {
            for (let i in nextProps) {
                if (nextProps[i] && nextProps[i].statusCode) {
                    if (nextProps[i].statusCode == 401) {
                        this.props.history.push("/logout")
                    } else if (nextProps[i].statusCode !== 200 && nextState == this.state) {
                       var alerts = this.state.alerts
                        alerts.push({
                            modal: true,
                            message: nextProps[i].message,
                            type: "danger"
                        })
                        this.setState({
                            alerts: alerts
                        })
                    }
                    
                }
            }
        } catch (e) { }

        try {

            if (nextProps.online.statusCode == 200 && nextState == this.state) {
                let filter = this.state.filter
                filter.cursor = nextProps.online.data.pagination.cursor
                this.setState({
                    filter: filter
                })
            }
        } catch (e) { }

        try {
            if (nextProps.killstate !== this.props.killstate) {
                this.props.getOnline(this.state.filter)
            }
        } catch (e) { }


        return true;
    } 
    renderAlert(alert, id) {
        return (
            <Alert color={alert.type} isOpen={alert.modal} fade={false} key={id} toggle={e => this.onDismiss(e, id)}>
                {alert.message}
            </Alert>
        )
    }
    onDismiss(e, id) {
        let alerts = this.state.alerts
        alerts[id].modal = false

        this.setState({
            alerts: alerts
        })
    }



    componentDidMount() {
        this.props.getOnline(this.state.filter)
    }

    kill(ev, key) {
        this.props.kill(key)
    }

    renderUsers(data, key) {

        var updated, created, ttl = "-"
        // if (data.created) {
        //     created = Util.s2ss(data.created, 'HH:mm', 'MM/DD')
        // }

        // if (data.updated) {
        //     updated = Util.s2ss(data.updated, 'HH:mm', 'MM/DD')
        // }

        ttl = Util.s2s(data.ttl)

        let idle = moment().unix() - data.created
        // if (data.updated) {
        //     idle = moment().unix() - data.updated
        // }
        // let idleString = Util.s2s(idle)


        return (
            <tr key={key} className={`${idle > 172800 ? "bg-warning" : typeof data.created == "number" && !data.updated ? "bg-danger" : ""}`}>
                <td>{Number(key) + 1}</td>
                <td >{data.name}</td>
                <td>{data.lineNumber ? data.lineNumber : '-'}</td>
                <td>{data.brandModel ? data.brandModel : '-'}</td>
                <td>{data.system ? data.system : '-'}</td>
                <td>{data.appVersion ? data.appVersion : '-'}</td>
                <td>{LANGUAGE[data.source]}</td>
                <td>{data.username}</td>
                <td>{ttl}</td>
                <td>
                    <Button color="link" className="kill" onClick={(ev) => this.kill(ev, data.key)} title="强制下线">
                        <i className="iconfont">&#xe60d;</i>
                    </Button>
                    <Button color="link" onClick={ev => this.toggle(ev, data)}>
                        <i className="iconfont iconjson"></i>
                    </Button>
                </td>
            </tr >
        )
    }

    toggle(ev, data) {
        this.setState({
            modal: !this.state.modal,
            ModalTitle: "详细信息",
            modalBody: <JsonView obj={data} showLineNumbers />
        })
    }

    handleFilter(e) {
        let filter = this.state.filter
        filter[e.target.name] = e.target.value

        this.setState({
            filter: filter
        })
    }

    search(e) {
        let filter = this.state.filter
        filter.page = 1
        this.setState({ filter: filter })
        this.props.getOnline(filter)
    }

    renderCurrentPager() {
        return (
            <div className="d-loader-box">
                <div className="loader-15"></div>
            </div>
        )
    }

    renderPagination(pagination, currentPager) {
        let nextDisabled = ""
        if (pagination.current === pagination.page) {
            nextDisabled = "disabled"
        }

        let prevDisabled = ""
        if (pagination.current === 1) {
            prevDisabled = "disabled"
        }

        return (
            <ul className="pagination justify-content-center">
                <li className={`page-item ${prevDisabled}`}>
                    <button className="page-link" onClick={(ev) => this.pager(ev, 1)}>First</button>
                </li>

                <li className={`page-item ${prevDisabled}`}>
                    <button className="page-link" onClick={(ev) => this.pager(ev, this.state.filter.page - 1)}>Prev</button>
                </li>

                <li className="page-item active">
                    <span className="page-link">
                        {currentPager}
                        <span className="sr-only">(current)</span>
                    </span>
                </li>

                <li className={`page-item ${nextDisabled}`}>
                    <button className={`page-link ${nextDisabled}`} onClick={(ev) => this.pager(ev, this.state.filter.page + 1)}>Next</button>
                </li>

                <li className={`page-item ${nextDisabled}`}>
                    <button className={`page-link`} onClick={(ev) => this.pager(ev, pagination.page)}>Last</button>
                </li>
            </ul>
        )
    }

    pager(ev, page) {
        if (page > 0) {
            let filter = this.state.filter
            filter.page = page
            this.setState({ filter: filter })
            this.props.getOnline(filter)
        }
    }

    render() {
        try {
            var users, pagination, currentPager

            if (this.props.online && Object.keys(this.props.online.data.rows).length) {
                currentPager = `${this.props.online.data.pagination.current} of ${this.props.online.data.pagination.page}`
                if (this.props.online.data.pagination.current !== this.state.filter.page) {
                    currentPager = this.renderCurrentPager()
                }

                pagination = this.renderPagination(this.props.online.data.pagination, currentPager)
                users = Object.keys(this.props.online.data.rows).map(key => this.renderUsers(this.props.online.data.rows[key], key))
            }
        } catch (e) { console.log(e) }

        
        try {
            var alerts = Object.keys(this.state.alerts).map(key => this.renderAlert(this.state.alerts[key], key))
        } catch (e) { }

        return (
            <AdminLayout modal={this.state.modal} props={this.props} className="online">
                <Modal isOpen={this.state.modal} toggle={(ev) => this.toggle(ev)}>
                    <ModalHeader toggle={(ev) => this.toggle(ev)} className="text-danger">{this.state.ModalTitle}</ModalHeader>
                    <ModalBody id="modal-body">{this.state.modalBody}</ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={(ev) => this.toggle(ev)}>好的</Button>
                    </ModalFooter>
                </Modal>
                <FormGroup row className="search-box">
                    <Label for="examplePassword">行数</Label>
                    <Input bsSize="sm" type="select" name="size" onChange={(ev) => this.handleFilter(ev)} defaultValue={this.state.filter.size}>
                        <option>10</option>
                        <option>15</option>
                        <option>20</option>
                        <option>25</option>
                        <option>30</option>
                    </Input>

                    <Label for="examplePassword">来源</Label>
                    <Input bsSize="sm" type="select" name="type" onChange={(ev) => this.handleFilter(ev)}>
                        <option value="all">{LANGUAGE["all"]}</option>
                        <option value="api">{LANGUAGE["api"]}</option>
                        <option value="device">{LANGUAGE["device"]}</option>
                        <option value="weixin">{LANGUAGE["weixin"]}</option>
                        <option value="anybody">{LANGUAGE["anybody"]}</option>
                    </Input>

                    <Label for="examplePassword">自定义</Label>
                    <Input bsSize="sm" type="text" name="ext" onChange={(ev) => this.handleFilter(ev)} />

                    <button className="btn btn-secondary" onClick={(ev) => this.search(ev)}>搜索</button>
                </FormGroup>

                <Table >
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>昵称</th>
                            <th>终端</th>
                            <th>设备</th>
                            <th>系统</th>
                            <th>应用</th>
                            <th>来源</th>
                            <th>用户名</th>
                            <th>剩余</th>
                            <th>操作</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users}
                    </tbody>
                </Table>
                <Col style={{ textAlign: "center" }}>
                    {pagination}
                </Col>

            </AdminLayout>
        )

    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        online: state.adminData.online,
        killstate: state.adminData.kill
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getOnline: (filter) => dispatch(getOnlineAction(filter)),
        kill: (type) => dispatch(killAction(type))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Online);