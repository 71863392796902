import MyFetch from '../../../utils/MyFetch';
import {
    CONFIG,
    MAKE_WX_URI,
    GET_LAST_ID
} from './actionTypes';

// =====控制台=====
export const getConfigAction = () => {
    return (dispatch) => {
        const path = `sms/config`;
        MyFetch.get(path).then((data) => {
            dispatch(setConfigAction({
                config: data
            }))
        })
    }
}
export const setConfigAction = (rows) => ({
    type: CONFIG,
    data: rows
})


// =====工具箱=====
// 微信连接
export const getLastIdAction = (mid) => {
    return (dispatch) => {
        const path = `/system/message`;
        MyFetch.get(path).then((data) => {
            dispatch(setGetLastIdAction({
                message: data
            }))
        })
    }
}

export const setGetLastIdAction = (rows) => ({
    type: GET_LAST_ID,
    data: rows
})

export const makeWxUriAction = (mid) => {
    return (dispatch) => {
        const path = `admin/tools/wx/uri/${mid}`;
        MyFetch.get(path).then((data) => {
            dispatch(setMakeWxUriAction({
                wxUri: data
            }))
        })
    }
}

export const setMakeWxUriAction = (rows) => ({
    type: MAKE_WX_URI,
    data: rows
})