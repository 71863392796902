import MyFetch from '../../utils/MyFetch';
import {
    PAGE
} from './actionTypes';

export const getPageAction = (id) => {
    return (dispatch) => {
        const path = `page?id=${id}`;
        MyFetch.get(path).then((data) => {
            dispatch(setPageAction({
                page: data
            }))
        })
    }
}
export const setPageAction = (rows) => ({
    type: PAGE,
    data: rows
})
