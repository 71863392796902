export const LANGUAGE = {
    "all": "全部",
    "api": "网站",
    "device": "设备",
    "weixin": "微信",
    "anybody": "匿名设备",
    "cell": "手机号码",
    "ip": "IP地址",
    "error": "错误",
    "warning": "警告",
    "info": "调试",
    "chg-pwd": "修改密码",
    "login": "登录",
    "system": "系统",
    "unlock": "用户解锁",
    "user lock": "用户锁定",
    "force down": "强制下线",
    "noLogin view": "游客查看",
    "normal": "正常",
    "locked": "锁定",
    "Subscribe": "小程序消息",
    "Yes":"是",
    "No":"否",
    "null":"未知"
}