import { createStore, applyMiddleware} from 'redux';
import thunkMiddleware from 'redux-thunk';
import { combineReducers } from 'redux';
import { reducer as SSO } from './views/SSO';
import { reducer as Message } from './views/Message';
import { reducer as User } from './views/User'
import { reducer as Contact } from './views/Contact'
import { reducer as Admin } from './views/Admin'
import { reducer as Weixin } from './views/Weixin'

const reducers = combineReducers({
    loginData: SSO,
    homeData: Message,
    userData: User,
    contactData: Contact,
    adminData: Admin,
    weixinData: Weixin
})

const initState = {};

// const initStateMap = fromJS(initState);
// 根reducers, 初始化initState， 中间件

const store = createStore(reducers, initState, applyMiddleware(thunkMiddleware));
export default store;
