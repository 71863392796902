import React from 'react';
import { connect } from 'react-redux';
import ErrorPage from '../../components/ErrorPage'
import AdminLayout from '../../containers/Admin/AdminLayout'
import { LANGUAGE } from '../../config/Language';
import { getDashboardAction, getSystemInfoAction, getSettingsCacheAction } from './actions'
import Util from '../../utils/Util'
import Helmet from 'react-helmet'
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    FormGroup,
    CardGroup, Alert,
    CustomInput
} from 'reactstrap';

class Dashboard extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showElem: window.sessionStorage.role == 'admin',
            alerts: [],
            clock: {
                timezone: null,
                timezoneName: null,
                current: 0,
                uptime: 0
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        try {
            for (let i in nextProps) {
                if (nextProps[i] && nextProps[i].statusCode) {
                    if (nextProps[i].statusCode == 401) {
                        this.props.history.push("/logout")
                    } else if (nextProps[i].statusCode !== 200 && nextState == this.state) {
                        var alerts = this.state.alerts
                        alerts.push({
                            modal: true,
                            message: nextProps[i].message,
                            type: "danger"
                        })
                        this.setState({
                            alerts: alerts
                        })
                    }
                }
            }
        } catch (e) { }

        return true;
    }
    renderAlert(alert, id) {
        return (
            <Alert color={alert.type} isOpen={alert.modal} fade={false} key={id} toggle={e => this.onDismiss(e, id)}>
                {alert.message}
            </Alert>
        )
    }
    onDismiss(e, id) {
        let alerts = this.state.alerts
        alerts[id].modal = false

        this.setState({
            alerts: alerts
        })
    }



    componentDidMount() {
        this.props.getDashboard()
        this.props.getSystemInfo()
        this.props.getSettings()
    }


    render() {
        var modal, user = { users: {} }, api = {}, message = { last: 0, readed: {} }, contact = {}, deny = {}, log = {}, os = { mem: {} }, info = {}
        var errorExpire, errorThreshold, apiExpire, deviceExpire, wxExpire, denyCell, denyIp

        try {
            let dashboard = this.props.dashboard && this.props.dashboard.data ? this.props.dashboard.data : {}
            if (Object.keys(dashboard).length) {
                user = dashboard.user
                api = dashboard.api
                contact = dashboard.contact
                message = dashboard.message
                deny = dashboard.deny
                log = dashboard.log
                os = dashboard.osInfo
            }

            let settingsCache = this.props.settingsCache && this.props.settingsCache.data ? this.props.settingsCache.data : {}
            if (Object.keys(settingsCache).length) {
                errorExpire = Util.s2s(settingsCache.login_error_expire)
                errorThreshold = settingsCache.login_error_threshold
                apiExpire = Util.s2s(settingsCache.token_expire_api)
                deviceExpire = Util.s2s(settingsCache.token_expire_device)
                wxExpire = Util.s2s(settingsCache.token_expire_weixin)
                denyCell = settingsCache.deny_cell
                denyIp = settingsCache.deny_ip
            }

            info = this.props.systemInfo && this.props.systemInfo.data ? this.props.systemInfo.data : {}

        } catch (e) { }

        
        try {
            var alerts = Object.keys(this.state.alerts).map(key => this.renderAlert(this.state.alerts[key], key))
        } catch (e) { }

        return (
            <AdminLayout modal={this.state.modal} props={this.props} className="dashboard">
                <Helmet>
                    <script>{`
                        
                    `}
                    </script>
                </Helmet>
                {!modal ? (
                    <div>
                        <CardGroup className="user-info">
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="iconfont iconAPI"></i>接口信息</CardTitle>
                                    <span className="lable">服务</span><span className="value">{info ? info.name : '0'}</span>
                                    <div></div>
                                    <span className="lable">环境</span><span className="value">{info ? info.env : 'alpha'}</span>
                                    <span className="lable">版本</span><span className="value">{info ? info.version : '0'}</span>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="iconfont icontigongzhe"></i>服务信息</CardTitle>
                                    <span className="lable">接口数</span><span className="value">{api ? api.api : 0}</span>
                                    <span className="lable">请求数</span><span className="value">{api ? api.request : 0}</span>
                                    <div></div>
                                    <span className="lable">运行时</span><span className="value">{os.time ? Util.s2s(os.time.uptime) : 0}</span>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="iconfont iconjincheng"></i>进程</CardTitle>
                                    {/* <span className="lable">NAME</span><span className="value">{os.processes ? os.processes.name : ''}</span> */}
                                    <span className="lable">CPU</span><span className="value">{os.processes ? os.processes.pcpu : 0}%</span>
                                    <span className="lable">内存</span><span className="value">{os.processes ? Util.space(os.processes.pmem) : 0}</span>
                                    <div></div>
                                    <span className="lable">PID</span><span className="value">{os.processes ? os.processes.pids.join(',') : ''}</span>
                                </CardBody>
                            </Card>

                        </CardGroup>
                        <CardGroup>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="iconfont iconfuzaishuaifenxi"></i>系统负载</CardTitle>
                                    <span className="lable">loadavg</span><span className={`value text-${os.load && os.load.alt ? os.load.alt : ''}`}>{os.load ? os.load.avgload : 0}</span>
                                    <div></div>
                                    <span className="lable">used</span><span className={`value text-${os.load && os.load.clt ? os.load.clt : ''}`}>{os.load ? os.load.currentload : 0}%</span>
                                    <span className="lable">idel</span><span className={`value text-${os.load && os.load.cit ? os.load.cit : ''}`}>{os.load ? os.load.currentload_idle : 0}%</span>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="iconfont iconneicun"></i>内存</CardTitle>
                                    <span className="lable">total</span><span className="value">{Util.space(os.mem.total)}</span>
                                    <div></div>
                                    <span className="lable">used</span><span className={`value text-${os.load && os.mem.ft ? os.mem.ft : ''}`}>{Util.space(os.mem.used)}</span>
                                    <span className="lable">free</span><span className={`value text-${os.load && os.mem.ut ? os.mem.ut : ''}`}>{Util.space(os.mem.free)}</span>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="iconfont iconxianzhixinxi"></i>登录限制</CardTitle>
                                    <span className="lable">登录失败阈值</span><span className="value">{errorThreshold}次</span>
                                    <div></div>
                                    <span className="lable">登录失败锁定</span><span className="value">{errorExpire}</span>
                                </CardBody>
                            </Card>
                        </CardGroup>
                        <CardGroup className="user-info">
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="iconfont icondangqianzaixianyonghushu"></i>用户信息</CardTitle>
                                    <span className="lable">总数</span><span className="value">{user ? parseInt(user.users[0] ? user.users[0] : 0) + parseInt(user.users[1] ? user.users[1] : 0) : 0}</span>
                                    <span className="lable">正常</span><span className="value text-success">{user && user.users[1] ? user.users[1] : 0}</span>
                                    <span className="lable">锁定</span><span className="value text-danger">{user ? parseInt(user.users[0] ? user.users[0] : 0) : 0}</span>
                                    <span className="lable">异常</span><span className="value text-danger">{user ? user.error : 0}</span>
                                    <div></div>
                                    <span className="lable">在线</span><span className="value text-success">{user ? user.api + user.device + user.error + user.weixin + user.anybody : 0}</span>
                                    <span className="lable">网站</span><span className="value">{user ? user.api : 0}</span>
                                    <span className="lable">设备</span><span className="value">{user ? user.device : 0}</span>
                                    <span className="lable">微信</span><span className="value">{user ? user.weixin : 0}</span>
                                    <span className="lable">未知</span><span className="value">{user ? user.anybody : 0}</span>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="iconfont iconicon-test"></i>消息信息</CardTitle>
                                    <span className="lable">已读</span><span className="value">{message ? message.readed['Y'] : 0}</span>
                                    <span className="lable">未读</span><span className="value">{message ? message.readed['N'] : 0}</span>
                                    <div></div>
                                    <span className="lable">最新ID</span><span className="value">{message ? message.last : 0}</span>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="iconfont icontongxunlu"></i>联系人信息</CardTitle>
                                    <span className="lable">待审核</span><span className="value text-warning">{contact ? (contact[2] ? contact[2] : 0) : 0}</span>
                                    <span className="lable">待维护</span><span className="value text-danger">{contact ? (contact[3] ? contact[3] : 0) : 0}</span>
                                    <div></div>
                                    <span className="lable">不共享</span><span className="value">{contact ? (contact[0] ? contact[0] : 0) : 0}</span>
                                    <span className="lable">共享</span><span className="value text-success">{contact ? (contact[1] ? contact[1] : 0) : 0}</span>
                                </CardBody>
                            </Card>
                        </CardGroup>
                        <CardGroup>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="iconfont iconjinzhi1"></i>黑名单</CardTitle>
                                    <span className="lable">手机号</span><span className={`value ${denyCell == 1 ? 'text-success' : 'text-danger'}`}>{denyCell == 1 ? '√' : '×'}</span>
                                    <span className="lable">IP地址</span><span className={`value ${denyIp == 1 ? 'text-success' : 'text-danger'}`}>{denyIp == 1 ? '√' : '×'}</span>
                                    <div></div>
                                    <span className="lable">手机号</span><span className="value">{deny ? deny.cell : 0}个</span>
                                    <span className="lable">IP地址</span><span className="value">{deny ? deny.ip : 0}个</span>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="iconfont iconrizhichaxun"></i>日志</CardTitle>
                                    <span className="lable">错误</span><span className="value text-danger">{log ? (log.error ? log.error : 0) : 0}</span>
                                    <span className="lable">警告</span><span className="value text-warning">{log ? (log.warning ? log.warning : 0) : 0}</span>
                                    <div></div>
                                    <span className="lable">调试</span><span className="value">{log ? (log.info ? log.info : 0) : 0}</span>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <CardTitle><i className="iconfont icontoken1"></i>Token有效期</CardTitle>
                                    <span className="lable">设备Token有效期</span><span className="value">{deviceExpire}</span>
                                    <span className="lable">微信Token有效期</span><span className="value">{wxExpire}</span>
                                    <div></div>
                                    <span className="lable">网站Token有效期</span><span className="value">{apiExpire}</span>
                                </CardBody>
                            </Card>
                        </CardGroup></div>) : null}
            </AdminLayout>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        dashboard: state.adminData.dashboard,
        systemInfo: state.adminData.systemInfo,
        settingsCache: state.adminData.settingsCache
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getDashboard: () => dispatch(getDashboardAction()),
        getSystemInfo: () => dispatch(getSystemInfoAction()),
        getSettings: () => dispatch(getSettingsCacheAction())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);