// MyFetch.js
const API_URL = process.env.REACT_APP_API

var methods = {
    post(path, data) {
        let storage = window.sessionStorage
        var options = {};
        if (storage.token !== '' && storage.token !== undefined) {
            options = {
                'Access-Control-Allow-Origin': '*',
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': storage.token
            };
        }
        return fetch(`${API_URL}/${path}`, {
            headers: new Headers(options),
            method: "post",
            mode: "cors",
            body: JSON.stringify(data)
        }).then(res => {
            return res.json();
        }).catch(e => {
            return { statusCode: 500, message: e }
        });
    },
    get(path, data) {
        let storage = window.sessionStorage
        var options = {};
        if (storage.token !== '' || storage.token !== undefined) {
            options = {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': storage.token
            };
        }

        return fetch(`${API_URL}/${path}`, {
            headers: new Headers(options),
            method: "GET",
            mode: "cors",
            data: data
        }).then(res => {
            return res.json();
        }).catch(e => {
            return { statusCode: 500, message: e }
        });
    },
    delete(path, data) {
        let storage = window.sessionStorage;
        var options = {};
        if (storage.token !== '' || storage.token !== undefined) {
            options = {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': storage.token
            };
        }
        return fetch(`${API_URL}/${path}`, {
            headers: new Headers(options),
            method: "DELETE",
            mode: "cors",
            data: data
        }).then(res => {
            return res.json();
        }).catch(e => {
            return { statusCode: 500, message: e }
        });
    },
    put(path, data) {
        let storage = window.sessionStorage;
        var options = {};
        if (storage.token !== '' || storage.token !== undefined) {
            options = {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': storage.token
            };
        }
        return fetch(`${API_URL}/${path}`, {
            headers: new Headers(options),
            method: "PUT",
            mode: "cors",
            body: JSON.stringify(data)
        }).then(res => {
            return res.json();
        }).catch(e => {
            return { statusCode: 500, message: e }
        });
    },
    patch(path, data) {
        let storage = window.sessionStorage;
        var options = {};
        if (storage.token !== '' || storage.token !== undefined) {
            options = {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=utf-8',
                'Authorization': storage.token
            };
        }
        return fetch(`${API_URL}/${path}`, {
            headers: new Headers(options),
            method: "PATCH",
            mode: "cors",
            body: JSON.stringify(data)
        }).then(res => {
            return res.json();
        }).catch(e => {
            return { statusCode: 500, message: e }
        });
    }
}

export default methods