import React from 'react';
import { connect } from 'react-redux';
import ErrorPage from '../../../components/ErrorPage'
import AdminLayout from '../../../containers/Admin/AdminLayout'
import { LANGUAGE } from '../../../config/Language';
import { getRealtimeAction, getArchiveAction } from './actions'
import BarTickAlign from '../../../components/Echarts/BarTickAlign'
import PieSimple from '../../../components/Echarts/PieSimple'
import LineStack from '../../../components/Echarts/LineStack'
import { Card, CardBody, CardGroup, Alert,CardTitle } from 'reactstrap'

class Message extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showElem: window.sessionStorage.role == 'admin',
            alerts: [],
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        try {
            for (let i in nextProps) {
                if (nextProps[i] && nextProps[i].statusCode) {
                    if (nextProps[i].statusCode == 401) {
                        this.props.history.push("/logout")
                    } else if (nextProps[i].statusCode !== 200 && nextState == this.state) {
                        var alerts = this.state.alerts
                        alerts.push({
                            modal: true,
                            message: nextProps[i].message,
                            type: "danger"
                        })
                        this.setState({
                            alerts: alerts
                        })
                    }
                }
            }
        } catch (e) { }
        return true;
    } 
    renderAlert(alert, id) {
        return (
            <Alert color={alert.type} isOpen={alert.modal} fade={false} key={id} toggle={e => this.onDismiss(e, id)}>
                {alert.message}
            </Alert>
        )
    }
    onDismiss(e, id) {
        let alerts = this.state.alerts
        alerts[id].modal = false

        this.setState({
            alerts: alerts
        })
    }



    componentDidMount() {
        this.props.getRealtime()
        this.props.getArchive()
    }

    render() {
        try {
            var totalByReaded = this.props.realtime.data.messageRealTime.totalByReaded
            var readedOptions = {
                title: {
                    text: "总体读取状态",
                    subtext: "实时数据"
                },
                legend: {
                    left: 'center',
                    top: 'bottom',
                    data: totalByReaded.legend
                },
                series: [
                    {
                        name: '读取状态',
                        type: 'pie',
                        radius: '55%',
                        center: ['50%', '60%'],
                        data: totalByReaded.rows,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            }

            var totalByType = this.props.realtime.data.messageRealTime.totalByType
            var typeOptions = {
                title: {
                    text: "总体信息分类",
                    subtext: "实时数据"
                },
                legend: {
                    left: 'center',
                    top: 'bottom',
                    data: totalByType.legend
                },
                series: [
                    {
                        name: '信息分类',
                        type: 'pie',
                        radius: '55%',
                        center: ['50%', '60%'],
                        data: totalByType.rows,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            }

            let totalRealTime = this.props.realtime.data.messageRealTime.totalRealTime
            var realTimeOptions = {
                title: {
                    text: "当日信息分类",
                    subtext: "实时数据"
                },
                color: ['#ff7392', '#e7bff0', '#ffd86c', '#ff9e83'],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: totalRealTime.xAxis,
                    axisTick: {
                        alignWithLabel: true
                    }
                },
                yAxis: {
                    type: 'value'
                },
                series: [{
                    data: totalRealTime.data,
                    barWidth: '60%',
                    type: 'bar'
                }]
            }

            let UserTotalRealTime = this.props.realtime.data.messageUserTotalRealTime
            var userTotalOrderOptions = {
                title: {
                    text: "信息量排行",
                    subtext: "实时数据"
                },
                legend: {
                    data: UserTotalRealTime.legend
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        data: UserTotalRealTime.xAxis
                    }
                ],
                series: UserTotalRealTime.rows
            }


            let dailyArchive = this.props.archive.data.messageArchiveDaily
            var dailyOptions = {
                title: {
                    text: '日报',
                    subtext: '30天归档数据'
                },
                legend: {
                    data: ['总量', '已读', '未读', '接收', '发送', '主叫', '被叫']
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        data: dailyArchive.xAxis
                    }
                ],
                series: dailyArchive.rows
            }

            let monthlyArchive = this.props.archive.data.messageArchiveMonthly
            var monthlyOptions = {
                title: {
                    text: '月报',
                    subtext: '24个月归档数据'
                },
                legend: {
                    data: monthlyArchive.legend
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        data: monthlyArchive.xAxis
                    }
                ],
                series: monthlyArchive.rows
            }
        } catch (e) { }

        
        try {
            var alerts = Object.keys(this.state.alerts).map(key => this.renderAlert(this.state.alerts[key], key))
        } catch (e) { }

        return (
            <AdminLayout modal={this.state.modal} props={this.props} className="reports" >
                <CardGroup>
                    <Card>
                        <CardBody>
                            <PieSimple options={readedOptions} />
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <PieSimple options={typeOptions} />
                        </CardBody>
                    </Card>
                </CardGroup>

                <CardGroup>
                    <Card>
                        <CardBody>
                            <BarTickAlign options={realTimeOptions} />
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <LineStack options={userTotalOrderOptions}></LineStack>
                        </CardBody>
                    </Card>
                </CardGroup>

                <CardGroup>
                    <Card>
                        <CardBody>
                            <LineStack options={dailyOptions} />
                        </CardBody>
                    </Card>

                    <Card>
                        <CardBody>
                            <LineStack options={monthlyOptions} />
                        </CardBody>
                    </Card>

                </CardGroup>
            </AdminLayout >
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        realtime: state.adminData.realtime,
        archive: state.adminData.archive,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getRealtime: () => dispatch(getRealtimeAction()),
        getArchive: () => dispatch(getArchiveAction()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Message);