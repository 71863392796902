import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { getUserAccountAction, getUserBindAction, getUserLogsAction, changeSettingsAction, getCodeAction, bindAction } from './actions';
import { LANGUAGE } from '../../config/Language';
import {
    Container,
    CardGroup,
    Card,
    CardBody,
    CardTitle,
    Table,
    Label,
    Col,
    Alert,
    CustomInput,
    Media,
    Button,
    ModalFooter,
    ModalBody,
    ModalHeader,
    Modal, Input
} from 'reactstrap';
import Util from '../../utils/Util'
import Topbar from '../../components/Topbar';
import './user.scss';
const MobileRegx = /^1[0-9]{10}$/

class Center extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showElem: window.sessionStorage.role == 'admin',
            filter: { page: 1 },
            alert: {
                modal: false,
                message: "",
                type: "danger"
            },
            getCode: {
                value: '发送验证码',
                disabled: false,
                interval: 60
            },
            data: {
                bind: {}
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {

        try {
            for (let i in nextProps) {
                if (nextProps[i] && nextProps[i].statusCode) {
                    if (nextProps[i].statusCode == 401) {
                        this.props.history.push("/logout")
                    } else if (nextProps[i].statusCode !== 200 && nextState == this.state) {
                       var alerts = this.state.alerts
                        alerts.push({
                            modal: true,
                            message: nextProps[i].message,
                            type: "danger"
                        })
                        this.setState({
                            alerts: alerts
                        })
                    }
                    
                }
            }
        } catch (e) { }

        try {
            if (nextProps.bind.statusCode !== 200) {
                if (nextState == this.state && nextProps.bind != this.props.bind) {
                    this.setState({
                        errorShow: true,
                        errorMessage: nextProps.bind.message,
                        ModalFooter: <Button color="primary" onClick={(ev) => this.bindModal(ev)}> 好的</Button>
                    })
                }
            } else {
                if (nextState == this.state) {
                    this.setState({
                        errorShow: false
                    })
                    if (nextProps.bind != this.props.bind)
                        this.props.getUserBind()
                }
            }
        } catch (e) { }

        try {
            if (nextProps.code.statusCode !== 200 && nextState == this.state && nextProps.code !== this.props.code) {
                this.setState({
                    errorShow: true,
                    errorMessage: nextProps.code.message,
                    ModalFooter: <Button color="primary" onClick={(ev) => this.bindModal(ev)}> 好的</Button >
                })
            }
        } catch (e) { }

        return true;
    } 
    renderAlert(alert, id) {
        return (
            <Alert color={alert.type} isOpen={alert.modal} fade={false} key={id} toggle={e => this.onDismiss(e, id)}>
                {alert.message}
            </Alert>
        )
    }
    onDismiss(e, id) {
        let alerts = this.state.alerts
        alerts[id].modal = false

        this.setState({
            alerts: alerts
        })
    }



    componentDidMount() {
        this.props.getUserAccount()
        this.props.getUserLogs(this.state.filter)
        this.props.getUserBind()
    }

    renderLogs(data, key) {
        return (
            <tr key={key}>
                <th scope="row">{Number(key) + 1}</th>
                <td>{Util.s2ss(data.created_at)}</td>
                <td>{data.country}</td>
                <td>{data.area}</td>
                <td>{data.ip}</td>
                <td>{LANGUAGE[data.source]}</td>
                <td><span className={`text-${data.level}`}>{data.reason}</span></td>
            </tr >
        )
    }

    handleInput(e, type) {
        let formData = this.state.data
        formData[type][e.target.name] = e.target.value
        this.setState({
            data: formData
        })
    }

    renderBind(data, key) {
        return (
            <Label className="bind-cell" key={key}>{data.number}</Label>
        )
    }

    toggle(e) {
        this.setState({
            errorShow: !this.state.errorShow
        })
    }

    bindModal(e) {
        this.setState({
            errorShow: true,
            errorTitle: '绑定手机号',
            errorMessage: <Col>
                <div className="input-group mb-2 mr-sm-2">
                    <Input type='text' placeholder="手机号码" name='mobile' defaultValue={this.state.data.bind.mobile} onChange={(e) => this.handleInput(e, 'bind')} autoComplete="off" />
                </div>
                <div className="input-group mb-2 mr-sm-2">
                    <Input type='text' placeholder="手机验证码" name='code' onChange={(e) => this.handleInput(e, 'bind')} autoComplete="off" />
                    <Input type="button" id="btn-get-code" value={this.state.getCode.value} disabled={this.state.getCode.disabled} onClick={(e) => this.getCode(e)}></Input>
                </div>
            </Col>,
            ModalFooter: <Button color="primary" onClick={(ev) => this.bind(ev)}> 绑定</Button >,
        })
    }

    getCode(e) {
        let bindData = this.state.data.bind
        if (!MobileRegx.test(bindData.mobile)) {
            if (e) {
                this.setState({
                    errorShow: true,
                    errorMessage: "请填写正确的手机号",
                    ModalFooter: <Button color="primary" onClick={(ev) => this.bindModal(ev)}> 好的</Button >,
                })
            }
        } else {
            let params = {
                mobile: this.state.data.bind.mobile,
                id: 'bind'
            }
            this.props.getCode(params)
            this.setState({
                ModalFooter: <Button color="primary" onClick={(ev) => this.bind(ev)}> 绑定</Button >,
            })
        }
    }

    bind(e) {
        let params = {
            mobile: this.state.data.bind.mobile,
            code: this.state.data.bind.code,
        }
        this.props.bindMobile(params)
    }

    pager(ev, page) {
        if (page > 0) {
            let filter = this.state.filter
            filter.page = page
            this.setState({ filter: filter })
            this.props.getUserLogs(filter)
        }
    }

    settings(e) {
        if (e.target.type == "checkbox")
            this.props.changeSettings(e.target.name, e.target.checked === true ? "Y" : "N")
    }

    renderCurrentPager() {
        return (
            <div className="d-loader-box">
                <div className="loader-15"></div>
            </div>
        )
    }

    renderPagination(pagination, currentPager) {
        let nextDisabled = ""
        if (pagination.current === pagination.page) {
            nextDisabled = "disabled"
        }

        let prevDisabled = ""
        if (pagination.current === 1) {
            prevDisabled = "disabled"
        }

        return (
            <ul className="pagination justify-content-center">
                <li className={`page-item ${prevDisabled}`}>
                    <button className="page-link" onClick={(ev) => this.pager(ev, 1)}>First</button>
                </li>

                <li className={`page-item ${prevDisabled}`}>
                    <button className="page-link" onClick={(ev) => this.pager(ev, this.state.filter.page - 1)}>Prev</button>
                </li>

                <li className="page-item active">
                    <span className="page-link">
                        {currentPager}
                        <span className="sr-only">(current)</span>
                    </span>
                </li>

                <li className={`page-item ${nextDisabled}`}>
                    <button className={`page-link ${nextDisabled}`} onClick={(ev) => this.pager(ev, this.state.filter.page + 1)}>Next</button>
                </li>

                <li className={`page-item ${nextDisabled}`}>
                    <button className={`page-link`} onClick={(ev) => this.pager(ev, pagination.page)}>Last</button>
                </li>
            </ul>
        )
    }

    render() {
        var maskLayout, logs, bind, pagination, currentPager
        var account = { base: {}, weixin: {}, ftqq: {}, settings: {} }

        try {
            if (this.props.bindlist && Object.keys(this.props.bindlist.data).length) {
                bind = Object.keys(this.props.bindlist.data).map(key => this.renderBind(this.props.bindlist.data[key], key))
            }

            if (this.props.logs && Object.keys(this.props.logs.data.rows).length) {
                logs = Object.keys(this.props.logs.data.rows).map(key => this.renderLogs(this.props.logs.data.rows[key], key))
                pagination = this.renderPagination(this.props.logs.data.pagination, `${this.props.logs.data.pagination.current} of ${this.props.logs.data.pagination.page}`)
                if (this.props.logs.data.pagination.current !== this.state.filter.page) {
                    currentPager = this.renderCurrentPager()
                }
            }

            if (this.props.account && Object.keys(this.props.account.data).length) {
                account = this.props.account.data
            }
        } catch (e) { }

        return (<div>
            <Helmet>
                <title>{`用户中心 - ${process.env.REACT_APP_NAME}`}</title>
            </Helmet>
            <Topbar modal={this.state.modal} props={this.props} />
            <Container className="user-center">
                <Modal isOpen={this.state.errorShow} toggle={(ev) => this.toggle(ev)}>
                    <ModalHeader toggle={(ev) => this.toggle(ev)} className="text-danger">{this.state.errorTitle}</ModalHeader>
                    <ModalBody>{this.state.errorMessage}</ModalBody>
                    <ModalFooter>
                        {this.state.ModalFooter}
                    </ModalFooter>
                </Modal>

                <Alert color={this.state.alert.type} isOpen={this.state.alert.modal} >
                    {this.state.alert.message}
                </Alert>

                <CardGroup className="user-info">
                    <Card>
                        <CardBody>
                            <CardTitle><i className="iconfont iconjibenxinxi"></i>基本信息</CardTitle>
                            <Col>
                                <span>账号昵称:</span>{account ? account.base.name : ""}
                            </Col>
                            <Col>
                                <span>登录账号:</span>{account ? account.base.username : ""}
                            </Col>
                            <Col>
                                <span>注册时间:</span>{Util.s2ss(account.base.created_at)}
                            </Col>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <CardTitle><i className="iconfont iconlianxi1"></i>联系信息</CardTitle>
                            <Col>
                                <span>联系手机:</span>{account ? account.base.mobile : ""}
                            </Col>
                            <Col>
                                <span>电子邮箱:</span>{account ? account.base.email : ""}
                            </Col>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <CardTitle><i className="iconfont iconanquan2"></i>账户安全</CardTitle>
                            <Col>
                                <span>登陆密码:</span>******
                                    <i className="iconfont" style={{ fontSize: 12 }}>&#xe68e;</i>
                            </Col>
                            <Col>
                                <span className="fl">免登保护:</span>
                                <CustomInput className="df" type="switch" id="secure" name="secure" onClick={(ev) => this.settings(ev)} checked={account.ftqq.secure} />
                            </Col>
                            <Col>
                                <span>查询密码:</span>******
                                <i className="iconfont" style={{ fontSize: 12 }}>&#xe68e;</i>
                            </Col>
                        </CardBody>
                    </Card>
                </CardGroup>

                <CardGroup className="user-info">
                    <Card>
                        <CardBody>
                            <CardTitle><i className="iconfont iconweixin1"></i>微信绑定</CardTitle>
                            <Col>
                                {account.weixin.isBind ? (<Media>
                                    <Media left className="avatar" style={{ paddingTop: 0 }}>
                                        <Media object src={`${account.weixin.avatar}`} style={{ width: 56, height: 56 }} />
                                    </Media>
                                    <Media body>
                                        <Col>
                                            <span>微信名:</span>{account.weixin.nickName}
                                        </Col>
                                        <Col>
                                            <span>绑定时间:</span>{Util.s2ss(account.weixin.bindTime)}
                                        </Col>
                                    </Media>
                                </Media>) :
                                    <div className="text-danger">请微信搜索小程序"有卡小号"，并绑定。</div>
                                }
                            </Col>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <CardTitle><i className="iconfont icontuisong1"></i>微信推送</CardTitle>
                            <Col>
                                <span>server酱Token:</span>{account.ftqq.token}
                                <i className="iconfont" style={{ fontSize: 12 }}>&#xe68e;</i>
                            </Col>
                            <Col>
                                <span className="fl">server酱推送:</span>
                                <CustomInput className="df" type="switch" id="push" name="push" onClick={(ev) => this.settings(ev)} checked={account.ftqq.push} />
                            </Col>
                            <Col>
                                <span className="fl">server酱消息加密:</span>
                                <CustomInput className="df" type="switch" id="secret" name="secret" onClick={(ev) => this.settings(ev)} checked={account.ftqq.secret} />
                            </Col>
                        </CardBody>
                    </Card>
                </CardGroup>
                <CardGroup className="user-info">
                    <Card>
                        <CardBody>
                            <CardTitle><i className="iconfont iconbangding"></i>绑定手机号</CardTitle>
                            <Col md={12} className="bind">
                                {bind ? (bind) : <div className="text-danger">您还没有绑定手机号</div>}
                                <Label>
                                    <Button color="primary" size="sm" onClick={(e) => this.bindModal(e)} >绑定</Button>{' '}
                                </Label>
                            </Col>
                        </CardBody>
                    </Card>
                </CardGroup>
                <CardGroup className="user-info">
                    <Card>
                        <CardBody>
                            <CardTitle><i className="iconfont iconrizhi"></i>登录日志</CardTitle>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>时间</th>
                                        <th>地区</th>
                                        <th>运营商</th>
                                        <th>IP</th>
                                        <th>终端</th>
                                        <th>状态</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {logs}
                                </tbody>
                            </Table>
                            <Col style={{ textAlign: "center" }}>
                                {pagination}
                            </Col>
                        </CardBody>
                    </Card>
                </CardGroup>
            </Container>
        </div>)

    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        account: state.userData.account,
        bindlist: state.userData.bindlist,
        change: state.userData.change,
        logs: state.userData.logs,
        bind: state.userData.bind,
        code: state.userData.code
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getUserBind: () => dispatch(getUserBindAction()),
        getUserAccount: () => dispatch(getUserAccountAction()),
        getUserLogs: (filter) => dispatch(getUserLogsAction(filter)),
        changeSettings: (key, value) => dispatch(changeSettingsAction(key, value)),
        getCode: (data) => dispatch(getCodeAction(data)),
        bindMobile: (data) => dispatch(bindAction(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Center);