import React from 'react';
import { connect } from 'react-redux';
import { getUsersAction, lockAction } from './actions';
import { LANGUAGE } from '../../config/Language';
import {
    Table,
    Col,
    Label,
    Input,
    FormGroup,
    Button, Alert,
    CustomInput, Modal, ModalBody, ModalHeader, ModalFooter
} from 'reactstrap';
import moment from 'moment'
import Util from '../../utils/Util';
import AdminLayout from '../../containers/Admin/AdminLayout'
import { JsonView } from 'json-view-for-react'


class Users extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showElem: window.sessionStorage.role == 'admin',
            alerts: [],
            filter: { page: 1 },
            modal: false,
            errorShow: false,
            errorMessage: ""
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        try {
            for (let i in nextProps) {
                if (nextProps[i] && nextProps[i].statusCode) {
                    if (nextProps[i].statusCode == 401) {
                        this.props.history.push("/logout")
                    } else if (nextProps[i].statusCode !== 200 && nextState == this.state) {
                        var alerts = this.state.alerts
                        alerts.push({
                            modal: true,
                            message: nextProps[i].message,
                            type: "danger"
                        })
                        this.setState({
                            alerts: alerts
                        })
                    }

                }
            }
        } catch (e) { }

        return true;
    }
    renderAlert(alert, id) {
        return (
            <Alert color={alert.type} isOpen={alert.modal} fade={false} key={id} toggle={e => this.onDismiss(e, id)}>
                {alert.message}
            </Alert>
        )
    }
    onDismiss(e, id) {
        let alerts = this.state.alerts
        alerts[id].modal = false

        this.setState({
            alerts: alerts
        })
    }



    componentDidMount() {
        this.props.getUsers(this.state.filter)
    }

    kill(ev, key) {
        this.props.kill(key)
    }

    toggle(ev, data) {
        this.setState({
            modal: !this.state.modal,
            ModalTitle: "详细信息",
            modalBody: <JsonView obj={data} showLineNumbers />
        })
    }

    renderSource(source) {
        return source
    }

    renderUsers(data, key) {

        var created
        if (data.reg_time) {
            created = Util.s2ss(data.reg_time, 'HH:mm', 'MM/DD')
        }

        return (
            <tr key={key}>
                <td>{data.id}</td>
                <td>{data.username}</td>
                <td>{data.name}</td>
                <td>{data.email}</td>
                <td>{data.mobile}</td>
                <td>
                    <CustomInput type="switch" id="deny_cell" name="deny_cell" onClick={(ev) => this.settings(ev)} defaultChecked={data.state} />
                </td>
                <td>
                    {data.ftqq == "{}" ? (<Button color="link" title="未设置" style={{ marginLeft: '1rem' }}><i className={`iconfont iconweishezhi text-warning`}></i></Button>) :
                        <span>
                            <Button color="link" title="绑定" ><i className={`iconfont iconbind ${data.ftqq.bind ? "text-success" : "text-error"}`}></i></Button>
                            <Button color="link" title="推送"><i className={`iconfont iconpush ${data.ftqq.push ? "text-success" : "text-error"}`}></i></Button>
                        </span>}
                </td>
                <td><Button color="link"><i className={`iconfont iconxiaochengxu ${data.weixin ? "text-success" : "text-error"}`}></i></Button></td>
                <td><Button color="link"><i className={`iconfont iconanzhuo ${data.settings ? "text-success" : "text-error"}`}></i></Button></td>
                <td>{created}</td>

                <td>
                    <Button color="link" onClick={ev => this.toggle(ev, data)}><i className="iconfont iconjson"></i></Button>
                </td>

            </tr >
        )

    }

    handleFilter(e) {
        let filter = this.state.filter
        filter[e.target.name] = e.target.value

        this.setState({
            filter: filter
        })
    }

    search(e) {
        let filter = this.state.filter
        filter.page = 1
        this.setState({ filter: filter })
        this.props.getUsers(filter)
    }

    renderCurrentPager() {
        return (
            <div className="d-loader-box">
                <div className="loader-15"></div>
            </div>
        )
    }

    renderPagination(pagination, currentPager) {
        let nextDisabled = ""
        if (pagination.current === pagination.page) {
            nextDisabled = "disabled"
        }

        let prevDisabled = ""
        if (pagination.current === 1) {
            prevDisabled = "disabled"
        }

        return (
            <ul className="pagination justify-content-center">
                <li className={`page-item ${prevDisabled}`}>
                    <button className="page-link" onClick={(ev) => this.pager(ev, 1)}>First</button>
                </li>

                <li className={`page-item ${prevDisabled}`}>
                    <button className="page-link" onClick={(ev) => this.pager(ev, this.state.filter.page - 1)}>Prev</button>
                </li>

                <li className="page-item active">
                    <span className="page-link">
                        {currentPager}
                        <span className="sr-only">(current)</span>
                    </span>
                </li>

                <li className={`page-item ${nextDisabled}`}>
                    <button className={`page-link ${nextDisabled}`} onClick={(ev) => this.pager(ev, this.state.filter.page + 1)}>Next</button>
                </li>

                <li className={`page-item ${nextDisabled}`}>
                    <button className={`page-link`} onClick={(ev) => this.pager(ev, pagination.page)}>Last</button>
                </li>
            </ul>
        )
    }

    pager(ev, page) {
        if (page > 0) {
            let filter = this.state.filter
            filter.page = page
            this.setState({ filter: filter })
            this.props.getUsers(filter)
        }
    }

    render() {
        try {
            var users, pagination, currentPager

            if (this.props.users && Object.keys(this.props.users.data.rows).length) {
                currentPager = `${this.props.users.data.pagination.current} of ${this.props.users.data.pagination.page}`
                if (this.props.users.data.pagination.current !== this.state.filter.page) {
                    currentPager = this.renderCurrentPager()
                }

                pagination = this.renderPagination(this.props.users.data.pagination, currentPager)
                users = Object.keys(this.props.users.data.rows).map(key => this.renderUsers(this.props.users.data.rows[key], key))
            }
        } catch (e) { console.log(e) }

        
        try {
            var alerts = Object.keys(this.state.alerts).map(key => this.renderAlert(this.state.alerts[key], key))
        } catch (e) { }

        return (
            <AdminLayout modal={this.state.modal} props={this.props} className="online">
                <Modal isOpen={this.state.modal} toggle={(ev) => this.toggle(ev)}>
                    <ModalHeader toggle={(ev) => this.toggle(ev)} className="text-danger">{this.state.ModalTitle}</ModalHeader>
                    <ModalBody id="modal-body">{this.state.modalBody}</ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={(ev) => this.toggle(ev)}>好的</Button>
                    </ModalFooter>
                </Modal>
                <FormGroup row className="search-box">
                    <Label for="examplePassword">行数</Label>
                    {/* <Col sm={1}> */}
                    <Input bsSize="sm" type="select" name="size" onChange={(ev) => this.handleFilter(ev)} defaultValue={this.state.filter.size}>
                        <option>10</option>
                        <option>15</option>
                        <option>20</option>
                        <option>25</option>
                        <option>30</option>
                    </Input>
                    {/* </Col> */}

                    <Label for="examplePassword">状态</Label>
                    {/* <Col sm={1}> */}
                    <Input bsSize="sm" type="select" name="state" onChange={(ev) => this.handleFilter(ev)}>
                        <option value="all">{LANGUAGE["all"]}</option>
                        <option value="1">{LANGUAGE["normal"]}</option>
                        <option value="2">{LANGUAGE["locked"]}</option>
                    </Input>

                    <Label for="examplePassword">微信推送</Label>
                    <Input bsSize="sm" type="select" name="ftqq" onChange={(ev) => this.handleFilter(ev)}>
                        <option value="all">{LANGUAGE["all"]}</option>
                        <option value="1">是</option>
                        <option value="2">否</option>
                    </Input>
                    <Label for="examplePassword">小程序</Label>
                    <Input bsSize="sm" type="select" name="weixin" onChange={(ev) => this.handleFilter(ev)}>
                        <option value="all">{LANGUAGE["all"]}</option>
                        <option value="1">是</option>
                        <option value="2">否</option>
                    </Input>
                    <Label for="examplePassword">客户端</Label>
                    <Input bsSize="sm" type="select" name="client" onChange={(ev) => this.handleFilter(ev)}>
                        <option value="all">{LANGUAGE["all"]}</option>
                        <option value="1">是</option>
                        <option value="2">否</option>
                    </Input>
                    {/* </Col> */}
                    <Label for="examplePassword">自定义</Label>
                    {/* <Col sm={1}> */}
                    <Input bsSize="sm" type="text" name="ext" onChange={(ev) => this.handleFilter(ev)} />
                    {/* </Col>
                    <Col sm={1}> */}
                    <button className="btn btn-secondary" onClick={(ev) => this.search(ev)}>搜索</button>
                    {/* </Col> */}
                </FormGroup>

                <Table >
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>昵称</th>
                            <th>姓名</th>
                            <th>邮箱</th>
                            <th>手机</th>
                            <th>状态</th>
                            <th>推送</th>
                            <th>小程序</th>
                            <th>同步</th>
                            <th>注册时间</th>
                            <th>操作</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users}
                    </tbody>
                </Table>
                <Col style={{ textAlign: "center" }}>
                    {pagination}
                </Col>

            </AdminLayout>
        )

    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        users: state.adminData.users,
        // killstate: state.adminData.kill
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getUsers: (filter) => dispatch(getUsersAction(filter)),
        Lock: (type) => dispatch(lockAction(type))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);