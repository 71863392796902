import MyFetch from '../../utils/MyFetch';
import {
    ACCOUNT,
    LOGS,
    BINDLIST,
    CHANGESETTINGS,
    CODE,
    BIND
} from './actionTypes';

// ===========
export const getUserLogsAction = (filter) => {
    var query = ""
    if (filter && Object.keys(filter).length > 0) {
        let where = []
        for (let k in filter) {
            where.push(`${k}=${filter[k]}`)
        }
        query = where.join('&')
    }

    return (dispatch) => {
        const path = `user/logs?${query}`;
        MyFetch.get(path).then((data) => {
            dispatch(setUserLoginLogsAction({
                logs: data
            }))
        })
    }
}
export const setUserLoginLogsAction = (rows) => ({
    type: LOGS,
    data: rows
})

// ===========
export const getUserAccountAction = () => {
    return (dispatch) => {
        const path = `user/account`;
        MyFetch.get(path).then((data) => {
            dispatch(setUserAccountAction({
                account: data
            }))
        })
    }
}
export const setUserAccountAction = (rows) => ({
    type: ACCOUNT,
    data: rows
})

// ===========
export const getUserBindAction = () => {
    return (dispatch) => {
        const path = `user/bind`;
        MyFetch.get(path).then((data) => {
            dispatch(setUserBindAction({
                bindlist: data
            }))
        })
    }
}

export const setUserBindAction = (rows) => ({
    type: BINDLIST,
    data: rows
})

// ===========
export const changeSettingsAction = (key, value) => {
    return (dispatch) => {
        const path = `user/settings/${key}`;
        MyFetch.put(path, {
            key: key,
            value: value
        }).then((data) => {
            dispatch(setChangeSettingsAction({
                change: data
            }))
        })
    }
}

export const setChangeSettingsAction = (rows) => ({
    type: CHANGESETTINGS,
    data: rows
})


// ===========
export const getCodeAction = (data) => {
    return (dispatch) => {
        MyFetch.post('sms/code', data).then((res) => {
            dispatch(setCodeAction({ code: res }))
        });
    }
}
export const setCodeAction = (data) => ({
    type: CODE,
    data: data
})

// ===========
export const bindAction = (data) => {
    return (dispatch) => {
        MyFetch.post('user/bind', data).then((res) => {
            dispatch(setBindAction({ bind: res }))
        });
    }
}
export const setBindAction = (data) => ({
    type: BIND,
    data: data
})