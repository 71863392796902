import React from 'react';
import { NavLink } from "react-router-dom";
import './LeftNav.scss'
import { Fade, NavItem } from 'reactstrap';
const menu = require('../config/adminMenu.json')

class LeftNav extends React.Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this)
    }

    toggle(e, key) {
        e.preventDefault()
        var fadeIn = this.state.fadeIn
        try {
            if (Object.keys(fadeIn).length) {
                for (let k in fadeIn) {
                    if (k == key) {
                        fadeIn[k] = !fadeIn[k]
                    } else {
                        fadeIn[k] = false
                    }
                }
            } else {
                fadeIn[key] = !fadeIn[key]
            }
        } catch (e) { console.log(e) }

        this.setState({
            fadeIn: fadeIn
        })
    }

    componentWillMount() {
        try {
            var fadeIn = {}
            for (let key in menu) {
                if (menu[key].children && Object.keys(menu[key].children).length) {
                    for (let k in menu[key].children) {
                        fadeIn[key] = false
                        if (menu[key].children[k].path.split('/', 3).join('/') == this.props.props.match.path.split('/', 3).join('/')) {
                            fadeIn[key] = true
                        }
                    }
                }
            }

            this.setState({
                fadeIn: fadeIn,
                menu: []
            })
        } catch (e) { }

    }

    renderSubMenu(key, item) {
        if (item.show !== 0)
            return (
                <NavLink exact to={item.path} className="sub-nav-link" target={item.target} title={key} key={`sub-nav-link-${key}`}>
                    <NavItem key={`item-${key}`} className={`${this.props.props.match.path == item.path ? "active" : ""}`}>
                        <i className="iconfont" dangerouslySetInnerHTML={{ __html: item.icon || '·'}}></i>
                        <span className="menu-text">{key}</span>
                        {item.target == "_blank" ? (
                            <i className="iconfont iconwailian1"></i>
                        ) : null}
                    </NavItem>
                </NavLink>
            )
    }

    renderMenu(key, item, subItems) {
        if (item.show !== 0)
            if (subItems) {
                let path = item.children[Object.keys(item.children)[0]].path.split('/', 3).join('/')

                return (
                    <NavLink to={path} className="nav-link" onClick={ev => this.toggle(ev, key)} key={key} title={key}>
                        <NavItem key={`item-${key}`}>
                            <i className="iconfont" dangerouslySetInnerHTML={{ __html: item.icon }}></i>
                            <span className="menu-text">{key}</span>
                            <span className="fr">
                                <i className="iconfont" dangerouslySetInnerHTML={{ __html: this.state.fadeIn[key] ? "&#xe67e;" : "&#xfe64;" }}></i>
                            </span>
                        </NavItem>
                        <Fade in={this.state.fadeIn[key]} tag="div" className="sub-items">
                            {subItems}
                        </Fade>
                    </NavLink>
                )
            } else {
                return (
                    <NavLink exact to={item.path} className="nav-link" key={key} target={item.target} title={key}>
                        <NavItem key={`item-${key}`}>
                            <i className="iconfont" dangerouslySetInnerHTML={{ __html: item.icon }}></i>
                            <span className="menu-text">{key}</span>
                            {item.target == "_blank" ? (
                                <i className="iconfont iconwailian1"></i>
                            ) : null}
                        </NavItem>
                    </NavLink>
                )
            }
    }

    render() {
        try {
            var menus = []
            for (let key in menu) {
                let subItems
                let item = menu[key]
                if (item.children && Object.keys(item.children).length) {
                    subItems = Object.keys(item.children).length && Object.keys(item.children).map(key => this.renderSubMenu(key, item.children[key]))
                }
                menus.push(this.renderMenu(key, item, subItems))
            }
        } catch (e) { console.log(e) }
        return (
            <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                {menus}
            </div>
        )
    }
}

export default LeftNav