import React from 'react';
import { connect } from 'react-redux';
// javascript plugin used to create scrollbars on windows
import { getLastIdAction, makeWxUriAction } from './actions';
import { LANGUAGE } from '../../../config/Language';
import {
    Col,
    Label,
    Input,
    FormGroup, Alert,
    Card, CardGroup
} from 'reactstrap';
import AdminLayout from '../../../containers/Admin/AdminLayout'

class WxURL extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showElem: window.sessionStorage.role == 'admin',
            alerts: [],
            errorShow: false,
            errorMessage: "",
            mid: null
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        try {
            for (let i in nextProps) {
                if (nextProps[i] && nextProps[i].statusCode) {
                    if (nextProps[i].statusCode == 401) {
                        this.props.history.push("/logout")
                    } else if (nextProps[i].statusCode !== 200 && nextState == this.state) {
                       var alerts = this.state.alerts
                        alerts.push({
                            modal: true,
                            message: nextProps[i].message,
                            type: "danger"
                        })
                        this.setState({
                            alerts: alerts
                        })
                    }
                    
                }
            }
        } catch (e) { }

        return true;
    } 
    renderAlert(alert, id) {
        return (
            <Alert color={alert.type} isOpen={alert.modal} fade={false} key={id} toggle={e => this.onDismiss(e, id)}>
                {alert.message}
            </Alert>
        )
    }
    onDismiss(e, id) {
        let alerts = this.state.alerts
        alerts[id].modal = false

        this.setState({
            alerts: alerts
        })
    }


    componentDidMount() {
        this.props.getLastId()
    }

    handleFilter(ev, value) {
        let v = ev.target.value || value
        this.setState({
            mid: v
        })
    }

    makeUri(ev) {
        this.props.makeWxUri(this.state.mid)
    }

    render() {

        try {
            var wxUrl, username, shorturl, id, lastId
            if (this.props.wxUri && this.props.wxUri.data) {
                wxUrl = this.props.wxUri.data.url
                shorturl = this.props.wxUri.data.shorturl
                username = this.props.wxUri.data.username
                id = this.props.wxUri.data.id
            }

            if (this.props.message && this.props.message.data) {
                lastId = this.props.message.data.last
            }

        } catch (e) {
            console.log(e)
        }

        
        try {
            var alerts = Object.keys(this.state.alerts).map(key => this.renderAlert(this.state.alerts[key], key))
        } catch (e) { }

        return (
            <AdminLayout modal={this.state.modal} props={this.props} className="wx-link">
                <CardGroup>
                    <Card >
                        <FormGroup row className="search-box">
                            <Label for="examplePassword">mID</Label>
                            <Input bsSize="sm" type="number" min="1" name="mid" onChange={(ev) => this.handleFilter(ev)} max={lastId} defaultValue={this.state.mid} />
                            <button bsSize="sm" className="btn btn-secondary" onClick={(ev) => this.makeUri(ev)} >生成</button>
                        </FormGroup>

                        <FormGroup className="search-box-result">
                            <Col>
                                Last ID： <a onClick={(ev) => this.handleFilter(ev, lastId)} name="mid" style={{ cursor: 'pointer' }}>{lastId}</a>
                            </Col>
                            <Col>
                                mID： {id}
                            </Col>
                            <Col>
                                用户名： {username}
                            </Col>
                            <Col>
                                长链接： {wxUrl}
                                {wxUrl ? (<a href={wxUrl} rel="noopener noreferrer" target="_blank"><i className="iconfont iconwailian1"></i></a>) : null}
                            </Col>
                            <Col>
                                短链接： {shorturl}
                                {shorturl ? (<a href={shorturl} rel="noopener noreferrer" target="_blank"><i className="iconfont iconwailian1"></i></a>) : null}
                            </Col>
                        </FormGroup>
                    </Card>
                </CardGroup>
            </AdminLayout>
        )

    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        wxUri: state.adminData.wxUri,
        message: state.adminData.message
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        makeWxUri: (mid) => dispatch(makeWxUriAction(mid)),
        getLastId: () => dispatch(getLastIdAction())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WxURL);