import React from 'react';
import { connect } from 'react-redux';
import { getLockedAction, unlockAction } from './actions';
import {
    Table,
    Label,
    Input,
    FormGroup,
    Button, Alert,
    Col
} from 'reactstrap';
import Util from '../../utils/Util';
import AdminLayout from '../../containers/Admin/AdminLayout'

class Locked extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showElem: window.sessionStorage.role == 'admin',
            alerts: [],
            filter: { page: 1, size: 10 },
            errorShow: false,
            errorMessage: ""
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        try {
            for (let i in nextProps) {
                if (nextProps[i] && nextProps[i].statusCode) {
                    if (nextProps[i].statusCode == 401) {
                        this.props.history.push("/logout")
                    } else if (nextProps[i].statusCode !== 200 && nextState == this.state) {
                        var alerts = this.state.alerts
                        alerts.push({
                            modal: true,
                            message: nextProps[i].message,
                            type: "danger"
                        })
                        this.setState({
                            alerts: alerts
                        })
                    }
                }
            }
        } catch (e) { }

        try {
            if (nextProps.unlockstate !== this.props.unlockstate) {
                this.props.getLocked(this.state.filter)
            }
        } catch (e) { }

        return true;
    }
    renderAlert(alert, id) {
        return (
            <Alert color={alert.type} isOpen={alert.modal} fade={false} key={id} toggle={e => this.onDismiss(e, id)}>
                {alert.message}
            </Alert>
        )
    }
    onDismiss(e, id) {
        let alerts = this.state.alerts
        alerts[id].modal = false

        this.setState({
            alerts: alerts
        })
    }



    componentDidMount() {
        this.props.getLocked(this.state.filter)
    }

    unlock(ev, key) {
        this.props.unlock(key)
    }

    renderUsers(data, key) {

        var ttl = Util.s2s(data.ttl)

        return (
            <tr key={key}>
                <td>{Number(key) + 1}</td>
                <td>{data.username}</td>
                <td>{data.count}</td>
                <td>{ttl}</td>
                <td><Button color="link" className="kill" onClick={(ev) => this.unlock(ev, data.username)} title="解锁">
                    <i className="iconfont">&#xe60d;</i></Button>
                </td>
            </tr >
        )

    }

    handleFilter(e) {
        let filter = this.state.filter
        filter[e.target.name] = e.target.value

        this.setState({
            filter: filter
        })
    }

    search(e) {
        let filter = this.state.filter
        filter.page = 1
        this.setState({ filter: filter })
        this.props.getLocked(filter)
    }

    renderCurrentPager() {
        return (
            <div className="d-loader-box">
                <div className="loader-15"></div>
            </div>
        )
    }

    renderPagination(pagination, currentPager) {
        let nextDisabled = ""
        if (pagination.current === pagination.page) {
            nextDisabled = "disabled"
        }

        let prevDisabled = ""
        if (pagination.current === 1) {
            prevDisabled = "disabled"
        }

        return (
            <ul className="pagination justify-content-center">
                <li className={`page-item ${prevDisabled}`}>
                    <button className="page-link" onClick={(ev) => this.pager(ev, 1)}>First</button>
                </li>

                <li className={`page-item ${prevDisabled}`}>
                    <button className="page-link" onClick={(ev) => this.pager(ev, this.state.filter.page - 1)}>Prev</button>
                </li>

                <li className="page-item active">
                    <span className="page-link">
                        {currentPager}
                        <span className="sr-only">(current)</span>
                    </span>
                </li>

                <li className={`page-item ${nextDisabled}`}>
                    <button className={`page-link ${nextDisabled}`} onClick={(ev) => this.pager(ev, this.state.filter.page + 1)}>Next</button>
                </li>

                <li className={`page-item ${nextDisabled}`}>
                    <button className={`page-link`} onClick={(ev) => this.pager(ev, pagination.page)}>Last</button>
                </li>
            </ul>
        )
    }

    pager(ev, page) {
        if (page > 0) {
            let filter = this.state.filter
            filter.page = page
            this.setState({ filter: filter })
            this.props.getOnline(filter)
        }
    }

    render() {
        try {
            var users, pagination, currentPager
            if (this.props.locked.data && Object.keys(this.props.locked.data.rows).length) {
                currentPager = `${this.props.locked.data.pagination.current} of ${this.props.locked.data.pagination.page}`
                if (this.props.locked.data.pagination.current !== this.state.filter.page) {
                    currentPager = this.renderCurrentPager()
                }

                pagination = this.renderPagination(this.props.locked.data.pagination, currentPager)
                users = Object.keys(this.props.locked.data.rows).map(key => this.renderUsers(this.props.locked.data.rows[key], key))
            }
        } catch (e) { }

        
        try {
            var alerts = Object.keys(this.state.alerts).map(key => this.renderAlert(this.state.alerts[key], key))
        } catch (e) { }

        return (
            <AdminLayout modal={this.state.modal} props={this.props} className="locked">

                <FormGroup row className="search-box">
                    <Label for="examplePassword">用户名</Label>
                    <Input bsSize="sm" type="text" name="user" onChange={(ev) => this.handleFilter(ev)} />
                    <button className="btn btn-secondary" onClick={(ev) => this.search(ev)}>搜索</button>
                </FormGroup>

                <Table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>用户姓名</th>
                            <th>错误次数</th>
                            <th>剩余时间</th>
                            <th>操作</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users}
                    </tbody>
                </Table>

                <Col style={{ textAlign: "center" }}>
                    {pagination}
                </Col>

            </AdminLayout>
        )

    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        locked: state.adminData.locked,
        unlockstate: state.adminData.unlock
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getLocked: (type) => dispatch(getLockedAction(type)),
        unlock: (type) => dispatch(unlockAction(type))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Locked);