import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { getListAction, readAction, deleteAction, getChatsAction } from './actions'
import moment from 'moment'
import Util from '../../utils/Util'
import _ from 'loadsh'
import {
    Row,
    Col,
    Card,
    Media,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Alert, Button
} from 'reactstrap'
import Topbar from '../../components/Topbar';
import './Inbox.scss';

class Inbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showElem: window.sessionStorage.role == 'admin',
            alert: {
                modal: false,
                message: "",
                type: "danger"
            },
            filter: {
                left: { page: 1, size: 20, state: 'all' },
                group: { page: 1, size: 20, sort: 'desc' },
            },
            list: [],
            chats: [],
            current: {},
            remove: {
                id: 0,
                title: null,
                body: null,
                modal: false
            }
        }
    }

    // render后
    componentDidMount() {
        window.addEventListener('resize', this.handleResize.bind(this))
        this.props.getList(this.state.filter.left)
    }

    handleResize = e => {
        this.render()
    }

    // 在组件接收到新的props或者state时被调用
    shouldComponentUpdate(nextProps, nextState) {
        console.log('Component SHOULD UPDATE!')

        try {
            for (let i in nextProps) {
                if (nextProps[i] && nextProps[i].statusCode) {
                    if (nextProps[i].statusCode == 401) {
                        this.props.history.push("/logout")
                    } else if (nextProps[i].statusCode !== 200 && nextState == this.state) {
                        var alerts = this.state.alerts
                        alerts.push({
                            modal: true,
                            message: nextProps[i].message,
                            type: "danger"
                        })
                        this.setState({
                            alerts: alerts
                        })
                    }

                }
            }
        } catch (e) { }

        try {
            if (nextProps.list.statusCode == 200 && this.props.list != nextProps.list) {
                let list = this.state.list
                list = list.concat(nextProps.list.data.rows)
                this.setState({ list: list })
            }
        } catch (e) { }

        try {
            if (nextProps.chats.statusCode == 200 && this.props.chats != nextProps.chats) {
                let chats = this.state.chats
                if (!chats[this.state.current.id])
                    chats[this.state.current.id] = []

                chats[this.state.current.id] = chats[this.state.current.id].concat(nextProps.chats.data.rows)
                this.setState({ chats: chats })
            }
        } catch (e) { }

        try {
            if (nextProps.deleted !== this.props.deleted) {
                for (let i in this.state.list) {
                    if (this.state.list[i].id == nextProps.deleted.data.id) {
                        delete this.state.list[i]
                    }
                }
            }
        } catch (e) { }

        try {
            if (!nextState.current.id) {
                this.getMsgGroup(nextProps.list.data.rows[0])
            }
        } catch (e) { }

        return true;
    }
    renderAlert(alert, id) {
        return (
            <Alert color={alert.type} isOpen={alert.modal} fade={false} key={id} toggle={e => this.onDismiss(e, id)}>
                {alert.message}
            </Alert>
        )
    }
    onDismiss(e, id) {
        let alerts = this.state.alerts
        alerts[id].modal = false

        this.setState({
            alerts: alerts
        })
    }


    // 在组件完成更新后立即调用
    componentDidUpdate(prevProps, prevState) {
        console.log('Component DID UPDATE!')
    }

    // 在组件从 DOM 中移除之前立刻被调用
    componentWillUnmount() {
        console.log('Component WILL UNMOUNT!')
    }


    getMsgGroup(ev, id) {
        id = id ? id : ev
        var msg = {}

        if (typeof id === "object") {
            msg = id
            id = msg.id
        } else {
            let rows = this.state.list
            msg = _.find(rows, (o) => { return o.id === id })
        }

        let current = this.state.current
        current.id = id

        let filter = this.state.filter
        filter.group.page = 1
        this.setState({
            current: current,
            filter: filter,
            chats: []
        })

        if (msg.readed === 'N' && msg.owner == 1) {
            this.props.read(id)
        }
        this.props.getChats(id, filter.group)
    }

    deleteMsg(id) {
        this.props.delete(id)
        let remove = this.state.remove
        remove.modal = !remove.modal
        this.setState({
            remove: remove
        })
    }

    toggle(ev) {
        this.setState({
            remove: {
                id: this.state.current.id,
                title: "请确认",
                body: ' 您确认要删除此对话吗？此操作不可恢复！',
                confirm: "删除",
                modal: !this.state.remove.modal
            }
        })
    }

    messageHeader(item) {
        return (
            <Card className={`message-header`}>

                <Col md={12}>
                    <Col className="avatar fl" >
                        <img src={`${item.static}${item.avatar}`} alt={item.fn} />
                    </Col>
                    <Col className="fl base-info">
                        <Row>
                            <dt>接收:</dt>
                            {/* <dd className={`sim sim-${item.sim}`}>{item.name}<span>&lt;{item.receiver}&gt;</span></dd> */}
                            <dd><span className="card-alias">[{item.name || '未知'}]</span><span className="fn">&lt;{item.receiver}&gt;</span></dd>
                        </Row>
                        <Row>
                            <dt>发送:</dt>
                            <dd>
                                {item.fn}
                                <span className={item.fn === item.sender ? "hide" : ""}>&lt;{item.sender}&gt;</span>
                            </dd>
                            <a href={`https://www.baidu.com/s?wd=${item.sender}`} rel="noopener noreferrer" target="_blank" className="btn-search">
                                <i className="iconfont">&#xe615;</i>
                            </a>
                        </Row>
                        <Row >
                            <dt>标签:</dt>
                            <dd className="apn-tag cell-mark-detail">{item.apn}</dd>
                            <div className="cell-mark">
                                <dd className="bg-warning cell-mark-detail">{item.mark}</dd>
                                <dd className="bg-warning cell-mark-detail">{item.location}</dd>
                            </div>
                        </Row>
                    </Col>
                </Col>
            </Card>)
    }

    delBtnShow(ev, id) {
        this.setState({ btn: id })
    }

    delBtnHide(ev) {
        this.setState({ btn: 0 })
    }

    renderRow(props, key, length) {
        try {
            var receive_at = moment(props.receive_at).format('M/D HH:mm')
            if (moment(props.receive_at).format('Y') !== moment().format('Y')) {
                receive_at = moment(props.receive_at).format('YY/M/D HH:mm')
            }

            if (this.props.readed) {
                if (this.props.readed.data.id === props.id && this.props.readed.statusCode === 200) {
                    _.find(this.props.list.data.rows, (o) => {
                        if (o.id == props.id) {
                            props.readed = "Y"
                        }
                    })
                }
            }

            var className = ""
            if (this.state.current.id === props.id) {
                className = "active"
            }
        } catch (e) { console.log(e) }

        return (
            <div className={key == 0 ? 'first msg-item' : (key == length - 1 ? 'last msg-item' : 'msg-item')}>
                <Media onClick={(ev) => this.getMsgGroup(ev, props.id)} className={className} key={key} onMouseOver={ev => this.delBtnShow(ev, props.id)} onMouseOut={ev => this.delBtnHide(ev)}>
                    <Media left className="avatar">
                        <Media object src={`${props.static}${props.avatar}`} alt={props.fn} />
                    </Media>
                    <Media body>
                        <Media heading>
                            <Row className="dt">
                                {/* <span className={`sim sim-${props.sim}`}>{props.fn.substring(0, 12)}</span> */}
                                <span><span className="card-alias">[{props.name || '未知'}]</span><span className="fn">{props.fn.substring(0, 8)}</span></span>
                                <span className={props.readed === 'N' ? "dot" : ""}></span>
                                <div className="fr" >
                                    <span >{receive_at}</span>
                                </div>
                            </Row>
                        </Media>
                        <Col className="p0">
                            <Row style={{ wordBreak: "break-all" }}>
                                <dd>{props.content}</dd>
                            </Row>
                        </Col>
                    </Media>
                </Media>
                <div className="btn-delete" style={{ display: this.state.btn == props.id ? 'block' : 'none' }} onMouseOver={ev => this.delBtnShow(ev, props.id)} onMouseOut={ev => this.delBtnHide(ev)}>
                    <i className="iconfont iconshanchu1 " onClick={(ev => this.toggle(ev))} ></i>
                </div>
            </ div>
        )
    }

    renderChat(key, item, length) {
        return (
            <Row className={key == 0 ? 'first chat' : (key == length - 1 ? 'last chat' : 'chat')}>
                <Col md={12} className="message-receive-at">
                    {Util.s2ss(item.receive_at)}
                </Col>
                <Col md={7} className={`message-content ${item.type}`}>
                    {item.content}
                </Col>
            </Row >
        )
    }

    scroll(e, type) {
        var filter = this.state.filter
        if (e.target.scrollHeight - (e.target.clientHeight + e.target.scrollTop) < 10) {
            filter[type].page++
            if (type == 'left') {
                this.props.getList(filter[type])
            } else if (type == 'group') {
                if (this.props.chats.data.pagination.page >= filter[type].page)
                    this.props.getChats(this.state.current.id, filter[type])
            }
            this.setState({
                filter: filter
            })
        }
    }

    handleFilter(ev) {
        var filter = this.state.filter
        filter.left[ev.target.name] = ev.target.value.trim()
        this.setState({
            filter: filter
        })
        if (ev.target.name == 'state') {
            this.setState({ list: [] })
            this.props.getList(filter.left)
        }

    }

    search(ev) {
        if (ev.keyCode == '13') {
            var filter = this.state.filter['left']
            this.setState({ list: [] })
            this.props.getList(filter)
        }
    }

    render() {
        try {
            var listBody, messageChat, messageHeader

            listBody = [<div className="msg-item search">
                <input autoComplete="off" className={this.state.filter.left.keyword ? 'focus' : ''} name="keyword" onChange={ev => this.handleFilter(ev)} onKeyDown={ev => this.search(ev)}></input>
                <Button color={this.state.filter.left.state == 'all' ? 'success' : 'secondary'} name="state" value="all" onClick={ev => this.handleFilter(ev)} >全部</Button>
                <Button color={this.state.filter.left.state == 'readed' ? 'success' : 'secondary'} name="state" value="readed" onClick={ev => this.handleFilter(ev)} >已读</Button>
                <Button color={this.state.filter.left.state == 'unread' ? 'success' : 'secondary'} name="state" value="unread" onClick={ev => this.handleFilter(ev)} >未读</Button>
            </div>]
            let rows = this.state.list
            if (Object.keys(rows).length > 0) {
                listBody = listBody.concat(Object.keys(rows).map(key => this.renderRow(rows[key], key, Object.keys(rows).length)))
                let chats = this.state.chats[this.state.current.id]

                messageHeader = this.messageHeader(chats[0])
                if (chats && Object.keys(chats).length) {
                    messageChat = Object.keys(chats).map(key => this.renderChat(key, chats[key], Object.keys(chats).length))
                }
            }
        } catch (e) { }

        return (
            <div>
                <Helmet>
                    <title>{`信息中心 - ${process.env.REACT_APP_NAME}`}</title>
                </Helmet>

                <Topbar modal={this.state.modal} props={this.props} />

                <div>
                    <Modal isOpen={this.state.remove.modal} toggle={(ev) => this.toggle(ev)} className="message-modal">
                        <ModalHeader toggle={(ev) => this.toggle(ev)}>{this.state.remove.title}</ModalHeader>
                        <ModalBody>{this.state.remove.body}</ModalBody>
                        <ModalFooter>
                            <Button color="danger" onClick={(ev => this.deleteMsg(this.state.remove.id))}>{this.state.remove.confirm}</Button>{' '}
                            <Button color="secondary" onClick={(ev) => this.toggle(ev)}>取消</Button>
                        </ModalFooter>
                    </Modal>
                </div>

                <div className="message">
                    <div className="message-row">
                        <div className="message-left" style={{ height: window.innerHeight - 43 }} onScroll={e => this.scroll(e, 'left')}>
                            {listBody}
                        </div>
                        <div className="detail" style={{ height: window.innerHeight - 43 }} onScroll={e => this.scroll(e, 'group')}>
                            {messageHeader}
                            {messageChat}
                        </div>
                    </div>
                </div>
            </div>)
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        list: state.homeData.list,
        readed: state.homeData.readed,
        deleted: state.homeData.deleted,
        chats: state.homeData.chats,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getList: (filter) => dispatch(getListAction(filter)),
        read: (id) => dispatch(readAction(id)),
        delete: (id) => dispatch(deleteAction(id)),
        getChats: (id, filter) => dispatch(getChatsAction(id, filter))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Inbox);