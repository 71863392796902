import {
    CODE,
    SET_LOGOUT,
    SET_LOGIN_INFO,
    CAPTCHA
} from './actionTypes';

import MyFetch from '../../utils/MyFetch';

export const loginAction = (loginData) => {
    return (dispatch) => {
        if (loginData.type == 'account') {
            delete loginData.mobile
            delete loginData.code
        } else if (loginData.type == 'mobile') {
            delete loginData.username
            delete loginData.password
        }

        // 登录
        MyFetch.post('sso', loginData).then((data) => {
            if (data.statusCode === 200) {
                var storage = window.sessionStorage
                for (let k in data.data) {
                    storage[k] = data.data[k]
                }
            }
            dispatch(setLoginInfoAction({ login: data }))
        })
    }
}
export const setLoginInfoAction = (userInfo) => ({
    type: SET_LOGIN_INFO,
    data: userInfo
})

// ===========
export const getCodeAction = (data) => {
    return (dispatch) => {
        MyFetch.post('sms/code/guest', data).then((res) => {
            dispatch(setCodeAction({ code: res }))
        });
    }
}
export const setCodeAction = (data) => ({
    type: CODE,
    data: data
})

// =========
export const deleteUserInfoAction = () => {
    return (dispatch) => {
        MyFetch.delete('sso').then((data) => {
            dispatch(logoutSuccessAction({ logout: data }))
        });
    }
}
export const logoutSuccessAction = (data) => ({
    type: SET_LOGOUT,
    data: data
})

// =======
export const refreshCaptchaAction = (token) => {
    return (dispatch) => {
        MyFetch.post('sso/captcha', { token: token, width: 163, height: 45, background: "#ddd" }).then((data) => {
            dispatch(setRefreshCaptchaAction({ captcha: data }));
        });
    }
}
export const setRefreshCaptchaAction = (userInfo) => ({
    type: CAPTCHA,
    data: userInfo
})






