import MyFetch from '../../utils/MyFetch';
import {
    LIST,
    READED,
    DELETED
} from './actionTypes';

export const getListAction = (filter) => {
    var query = ""
    if (filter && Object.keys(filter).length > 0) {
        let where = []
        for (let k in filter) {
            where.push(`${k}=${filter[k]}`)
        }
        query = where.join('&')
    }

    return (dispatch) => {
        const path = `message/list?${query}`;
        MyFetch.get(path).then((data) => {
            dispatch(setListInfoAction({
                list: data
            }))
        })
    }
}

export const readAction = (id) => {
    return (dispatch) => {
        const path = `message/${id}`;
        MyFetch.put(path, {
            readed: "Y"
        }).then((data) => {
            dispatch(setReadedAction({
                readed: data
            }))
        })
    }
}

export const deleteAction = (id) => {
    return (dispatch) => {
        const path = `message/${id}`;
        MyFetch.put(path, {
            deleted: "Y"
        }).then((data) => {
            dispatch(setDeletedAction({
                deleted: data
            }))
        })
    }
}

export const setListInfoAction = (rows) => ({
    type: LIST,
    data: rows
})

export const setReadedAction = (rows) => ({
    type: READED,
    data: rows
})
export const setDeletedAction = (rows) => ({
    type: DELETED,
    data: rows
})