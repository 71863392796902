import React from 'react';
import { connect } from 'react-redux';
import { getPageAction } from './actions'
import {
    Container,
    Alert
} from 'reactstrap'
import './Page.scss';
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'


class Page extends React.Component {

    constructor(props) {
        super(props)
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }
    renderAlert(alert, id) {
        return (
            <Alert color={alert.type} isOpen={alert.modal} fade={false} key={id} toggle={e => this.onDismiss(e, id)}>
                {alert.message}
            </Alert>
        )
    }
    onDismiss(e, id) {
        let alerts = this.state.alerts
        alerts[id].modal = false

        this.setState({
            alerts: alerts
        })
    }


    // render后
    componentDidMount() {
        let hash = window.location.hash.split('#')
        let id = hash[1]
        this.props.getPage(id)
    }


    render() {
        try {
            var data
            if (this.props.page.statusCode == 200) {
                data = this.props.page.data
            }
        } catch (e) { }
        return (
            <Container className="page">
                <ReactMarkdown plugins={[gfm]} children={data} />
            </Container>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        page: state.weixinData.page
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getPage: (id) => dispatch(getPageAction(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Page);