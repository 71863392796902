/* eslint no-shadow:0 */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import {
    Col,
    Form,
    FormGroup,
    Input, Button, Alert,
    Modal, ModalHeader, ModalBody, ModalFooter,
    TabContent, TabPane, Nav, NavItem, NavLink,
} from 'reactstrap';
import {
    loginAction,
    refreshCaptchaAction,
    getCodeAction
} from './actions';
import logo from '../../assets/img/logo.svg'
import loading from '../../assets/img/loading.svg'
import classnames from 'classnames';
import md5 from 'md5'

const MobileRegx = /^1[0-9]{10}$/

class Login extends React.Component {

    constructor(props) {
        super(props)
        this.handleInput = this.handleInput.bind(this);
        this.focus = this.focus.bind(this)
        this.blur = this.blur.bind(this)

        this.state = {
            errorShow: false,
            errorTitle: "错误提示",
            errorMessage: "",
            ModalFooter: <Button color="primary" onClick={(ev) => this.toggle(ev)}> 好的</Button >,
            caption: false,
            getCode: {
                value: '发送验证码',
                disabled: false,
                interval: 60
            },
            loginData: {
                type: 'mobile'
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (sessionStorage.token)
            this.props.history.push('/')

        try {
            if (nextProps.captcha.statusCode == 200 && nextProps.captcha !== this.props.captcha) {
                this.setState({ captcha: nextProps.captcha.data.captcha })
                setTimeout(() => {
                    this.getCodeModel()
                }, 100)
            }

            if (nextProps.captcha.statusCode !== 200 && nextState == this.state) {
                this.setState({
                    errorShow: true,
                    errorMessage: nextProps.captcha.message
                })
            }
        } catch (e) { }

        try {
            if (nextProps.login.statusCode !== 200 && nextState == this.state && nextProps.captcha == this.props.captcha) {
                this.setState({
                    errorShow: true,
                    errorMessage: nextProps.login.message
                })
            }
        } catch (e) { }

        try {
            if (nextProps.code.statusCode !== 200 && nextState == this.state && nextProps.code !== this.props.code) {
                this.setState({
                    errorShow: true,
                    errorMessage: nextProps.code.message,
                    ModalFooter: <Button color="primary" onClick={(ev) => this.getCodeModel(ev)}> 好的</Button >
                })
            } else if (nextProps.code.statusCode == 200) {
                if (this.state == nextState) {
                    var intv = this.state.getCode
                    intv.interval = nextProps.code.data.expire > 0 ? nextProps.code.data.expire : intv.interval
                    var that = this
                    var interval = setInterval(function () {
                        intv.interval--
                        intv.value = `发送验证码(${intv.interval})`
                        intv.disabled = true
                        if (intv.interval == 0) {
                            clearInterval(interval)
                            intv.value = `发送验证码`
                            intv.disabled = false
                            intv.interval = 60
                        }
                        that.setState({
                            errorShow: false,
                            getCode: intv
                        })
                    }, 1000)
                }
            }

        } catch (e) { }
        return true;
    }
    renderAlert(alert, id) {
        return (
            <Alert color={alert.type} isOpen={alert.modal} fade={false} key={id} toggle={e => this.onDismiss(e, id)}>
                {alert.message}
            </Alert>
        )
    }
    onDismiss(e, id) {
        let alerts = this.state.alerts
        alerts[id].modal = false

        this.setState({
            alerts: alerts
        })
    }


    componentDidMount() {
        this.refreshCaptcha()
    }

    handleInput(e) {
        let formData = this.state.loginData
        formData[e.target.name] = e.target.value
        this.setState({
            loginData: formData
        })
    }

    focus(e) {
        this.setState({
            focus: e.target.name
        })
    }

    blur(e) {
        if (e.target.name == this.state.focus) {
            this.setState({ focus: null })
        }
    }

    refreshCaptcha(e) {
        let token = md5(Math.random())
        let loginData = this.state.loginData
        loginData.token = token
        this.setState({ loginData: loginData, captcha: false })
        this.props.handleCaptchaRefresh(token)
    }

    toggle(e) {
        this.setState({
            errorShow: !this.state.errorShow
        })
    }

    tabToggle(e, type) {
        let formData = this.state.loginData
        formData.type = type
        this.setState({
            loginData: formData
        })
    }

    getCodeModel(e) {
        let loginData = this.state.loginData
        if (!MobileRegx.test(loginData.mobile)) {
            if (e) {
                this.setState({
                    errorShow: true,
                    errorMessage: "请填写正确的手机号",
                })
            }
        } else {
            this.setState({
                errorShow: true,
                errorTitle: "请输入验证码",
                errorMessage: <Col>
                    <div className="input-group mb-2 mr-sm-2">
                        <Input className="fl" type="text" maxLength="6" placeholder="验证码" defaultValue={this.state.loginData.captcha} name="captcha" onChange={this.handleInput} onBlur={this.blur} autoComplete="off" onFocus={this.focus} />
                        {this.state.captcha ? (<div className="captch" onClick={(e) => this.refreshCaptcha(e)} dangerouslySetInnerHTML={{ __html: this.state.captcha }}></div>) :
                            <img className="captch" onClick={(e) => this.refreshCaptcha(e)} src={loading} />}
                    </div>
                </Col>,
                ModalFooter: <Button color="primary" onClick={(ev) => this.getCode(ev)}> 发送</Button >,
            })
        }
    }

    getCode(e) {
        e.preventDefault()
        if (this.state.loginData.type == 'mobile') {
            var loginData = this.state.loginData
            if (!MobileRegx.test(loginData.mobile)) {
                this.setState({
                    errorShow: true,
                    errorMessage: "请填写正确的手机号",
                })
            } else {
                let info = {
                    token: loginData.token,
                    captcha: loginData.captcha,
                    mobile: loginData.mobile,
                    id: 'login'
                }
                this.props.getCode(info)
            }
        }
    }

    login(e) {
        e.preventDefault()
        var loginData = this.state.loginData

        for (let k in loginData) {
            if (loginData[k] !== undefined)
                loginData[k] = loginData[k].trim()
            else
                loginData[k] = ""
        }

        let state = 0
        let msg = ""

        if (this.state.loginData.type == 'mobile') {
            if (!this.state.loginData.mobile) {
                state = 1
                msg = "请输入手机号"
            } else if (!this.state.loginData.code) {
                state = 1
                msg = "请输入手机验证码"
            }
        } else if (this.state.loginData.type == 'account') {
            if (!this.state.loginData.username) {
                state = 1
                msg = "请输入用户名"
            } else if (!this.state.loginData.password) {
                state = 1
                msg = "请输入密码"
            } else if (!this.state.loginData.captcha) {
                state = 1
                msg = "请输入验证码"
            }
        }

        if (!state) {
            this.setState({
                loginData: loginData
            })
            this.props._login(loginData)
        } else {
            this.setState({
                errorShow: true,
                errorMessage: msg,
            })
        }
    }

    render() {

        // if (this.state.getCode.disabled) {
        //     var helmet = <Helmet>
        //         <script>{`                   
        //             var interval=3            
        //             var btnInterval = setInterval(function(){
        //                 var btnGetCode = document.getElementById('btn-get-code')
        //                 btnGetCode.value = '发送验证码(' + interval + ')'
        //                 if(interval==0){
        //                     clearInterval(btnInterval)
        //                     btnGetCode.value='发送验证码'
        //                     btnGetCode.disabled=false
        //                 }
        //                 interval--
        //             },1000) 
        //         `}</script>
        //     </Helmet>
        // }

        return (
            <div>
                <Modal isOpen={this.state.errorShow} toggle={(ev) => this.toggle(ev)}>
                    <ModalHeader toggle={(ev) => this.toggle(ev)} className="text-danger">{this.state.errorTitle}</ModalHeader>
                    <ModalBody>{this.state.errorMessage}</ModalBody>
                    <ModalFooter>
                        {this.state.ModalFooter}
                    </ModalFooter>
                </Modal>

                <div className="text-center mt-4">
                    <img className="mb-2" src={logo} alt="有卡小号" width="72" height="72" />
                    <h1 className="h3 mb-1 font-weight-normal">有卡小号</h1>
                </div>

                <Form className="form-signin" onSubmit={(e) => this.login(e)}>
                    <Input type="hidden" name="token" defaultValue={this.state.loginData.token}></Input>

                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.loginData.type === 'mobile' })}
                                onClick={(e) => { this.tabToggle(e, 'mobile'); }}>
                                手机号登录
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.loginData.type === 'account' })}
                                onClick={(e) => { this.tabToggle(e, 'account'); }} >
                                账号密码登录
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <TabContent activeTab={this.state.loginData.type}>
                        <TabPane tabId="mobile">

                            <FormGroup row>
                                <Col>
                                    <div className="input-group mb-2 mr-sm-2">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <i className={`iconfont iconshouji-copy-copy ${this.state.focus == "mobile" ? "focus" : ""}`}></i>
                                            </div>
                                        </div>
                                        <Input type="number" maxLength="11" minLength="11" placeholder="手机号" name="mobile" onChange={this.handleInput} onBlur={this.blur} onFocus={this.focus} />
                                    </div>
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Col>
                                    <div className="input-group mb-2 mr-sm-2">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <i className={`iconfont iconsafety ${this.state.focus == "code" ? "focus" : ""}`}></i>
                                            </div>
                                        </div>
                                        <Input type='text' placeholder="手机验证码" name='code' onChange={this.handleInput} onBlur={this.blur} autoComplete="off" onFocus={this.focus} />
                                        <Input type="button" id="btn-get-code" value={this.state.getCode.value} disabled={this.state.getCode.disabled} onClick={(e) => this.getCodeModel(e)}></Input>
                                    </div>
                                </Col>
                            </FormGroup>
                        </TabPane>

                        <TabPane tabId="account">
                            <FormGroup row>
                                <Col>
                                    <div className="input-group mb-2 mr-sm-2">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <i className={`iconfont iconnickname ${this.state.focus == "username" ? "focus" : ""}`}></i>
                                            </div>
                                        </div>
                                        <Input type="text" placeholder="用户名" name="username" onChange={this.handleInput} onBlur={this.blur} onFocus={this.focus} />
                                    </div>
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Col>
                                    <div className="input-group mb-2 mr-sm-2">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text"><i className={`iconfont iconlocking ${this.state.focus == "password" ? "focus" : ""}`}></i></div>
                                        </div>
                                        <Input type='password' placeholder="密码" name='password' onChange={this.handleInput} onBlur={this.blur} autoComplete="off" onFocus={this.focus} />
                                    </div>
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Col>
                                    <div className="input-group mb-2 mr-sm-2">
                                        <div className="input-group-prepend">
                                            <div className="input-group-text">
                                                <i className={`iconfont iconsafety ${this.state.focus == "captcha" ? "focus" : ""}`}></i>
                                            </div>
                                        </div>
                                        <Input className="fl" type="text" maxLength="6" placeholder="验证码" name="captcha" onChange={this.handleInput} onBlur={this.blur} autoComplete="off" onFocus={this.focus} />
                                        {this.state.captcha ? (<div className="captch" onClick={(e) => this.refreshCaptcha(e)} dangerouslySetInnerHTML={{ __html: this.state.captcha }}></div>) :
                                            <img className="captch" onClick={(e) => this.refreshCaptcha(e)} src={loading} />}
                                    </div>
                                </Col>
                            </FormGroup>
                        </TabPane>
                    </TabContent>

                    <FormGroup row>
                        <Col>
                            <Button color="success" className="btn btn-lg btn-primary btn-block" type="submit">登录</Button>
                        </Col>
                    </FormGroup>

                    <Col className="ucenter">
                        <div className="notice">
                            {this.state.loginData.type == 'account' ? (
                                <Link to="/password">忘记密码</Link>
                            ) : '首次登录即默认注册'}
                        </div>
                        <div className="notice">
                            登录即代表您已经同意<Link to="/page/privacy.html" target="blank">《用户注册协议》</Link>
                        </div>
                    </Col>

                    <p className="mt-5 mb-3 text-muted text-center">&copy; 2019-2020</p>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        token: state.loginData.token,
        captcha: state.userData.captcha,
        login: state.userData.login,
        code: state.loginData.code
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        _login: (loginData) => dispatch(loginAction(loginData)),
        handleCaptchaRefresh: (token) => dispatch(refreshCaptchaAction(token)),
        getCode: (data) => dispatch(getCodeAction(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
